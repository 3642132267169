import UserApi from "./UserApi";

class V1UserApi implements UserApi  {
    fetchCustom: (endpoint: string, ...args: any) => Promise<any>;

    constructor(fetchCustom: any) {
        this.fetchCustom = fetchCustom;
    }
    

    signIn = (emailAddress: string, password: string): Promise<any> => {
        return this.fetchCustom('/auth/signin', {
            method: 'POST',
            body: JSON.stringify({emailAddress, password })
        });
    }

    signUp = (firstName: string, lastName: string, emailAddress: string, password: string): Promise<any> => {
        return this.fetchCustom('/auth/signup', {
            method: 'POST',
            body: JSON.stringify({firstName, lastName, emailAddress, password })
        });
    }

    forgotPassword = (emailAddress: string): Promise<any> => {
        return this.fetchCustom('/auth/reset-password', {
            method: 'POST',
            body: JSON.stringify({emailAddress})
        });
    }

    resetPassword = (emailAddress: string, temporaryPassword: string, newPassword: string): Promise<any> => {
        return this.fetchCustom('/auth/change-password', {
            method: 'POST',
            body: JSON.stringify({emailAddress, temporaryPassword, newPassword})
        });
    }

}

export default V1UserApi;