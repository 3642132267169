import React from "react";
import NavBar from "./NavBar";
import { useAppSelector , useAppDispatch } from "../redux/hooks";
import {useHistory } from "react-router-dom";
import {
  selectApi
} from "../redux/reducers/MainReducer";
import { useForm, SubmitHandler } from 'react-hook-form';
import { InfoModal } from "./shared/InfoModal";
import { selectUser } from "../redux/reducers/MainReducer";
import {selectXeroAllProduct, 
        selectLinkUserToXero2sqlProductModal, 
        linkUserToXero2sqlProductAsync,
        setUpLinkUserToXero2sqlProductModal} from "../redux/reducers/Xero2SqlReducer"
        
interface IFormInput {
  userName: string;
  productName: string;
}

const AssignProduct = () => {
  const {  register,
    handleSubmit,
    formState: { errors } } = useForm<IFormInput>({ 
    mode: "onChange"
  });

  const history = useHistory();
  // Redux Selector
  const api = useAppSelector(selectApi);
  const userList = useAppSelector(selectUser);
  const xeroProductList = useAppSelector(selectXeroAllProduct);
  const modalState = useAppSelector(selectLinkUserToXero2sqlProductModal);

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
    const Id = data.userName
    const xero2SqlProductId= data.productName
    
    dispatch(linkUserToXero2sqlProductAsync({api, Id, xero2SqlProductId}))
    
  }
  
  return (
    <div>
      <NavBar />
      <InfoModal
          header={modalState.header}
          message={modalState.message}
          open={modalState.open}
          success={modalState.success}
          onOK={() => {
              dispatch(setUpLinkUserToXero2sqlProductModal())
              modalState.success && history.push("/");
          }}
      />
      <br />
      <br />
      <div className="card">
        <div className="card-header">
           <h3 className="font-weight-bold">Assign product to users</h3>
        </div>
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-sm-6 form-group">
              <h4>Customer name</h4>
              <hr />
              {userList?.map((res: any, key:any) => (
                <div className="input-group">
                  <span className="input-group-addon">
                    <input 
                      className="text-left"
                      type="radio" id={`userName${key}`}  value={res.Id} 
                      {...register(`userName`, {required: true})} 
                      checked
                    />
                    <label htmlFor={`userName${key}`} style={{padding: 10}}>{res.FirstName} {res.LastName}</label>
                  </span>
                </div>
                
              ))}
            </div>
            <div className="col-sm-6 form-group">
              <h4>Product List</h4>
              <hr />
              {xeroProductList?.map((res: any, key:any) => (
                <div className="input-group">
                  <span className="input-group-addon">
                    <input 
                      className=""
                      type="radio" 
                      id={`productName${key}`} 
                      value={res.Id}
                      {...register("productName", {required: true})} 
                      checked
                      />
                    <label htmlFor={`productName${key}`}  style={{padding: 10}}>{res.CompanyName}</label>
                  </span>
                </div>
                
              ))}
            </div>
            <div className="form-check">
                <small className="form-text text-danger">{(errors.userName || errors.productName) && <p>Choose both user name and product name</p>}</small>
            </div>
            <hr />
            <div>
                <button 
                    type="submit" 
                    className="btn btn-lg btn-primary btn-login text-uppercase mb-2 mx-2" 
                >Add</button>
                <button 
                    className="mx-2 btn btn-secondary btn-lg btn-login text-uppercase mb-2"
                    onClick={()=>history.push("/xero2sql-products")} 
                >Cancel</button>
            </div>
          </form>
                    
        </div>
      </div>
      
    </div>
      
  );
}

export default AssignProduct;
