import {  useState } from "react";
import {Link, useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from 'react-hook-form';
// Redux Info
import { useAppSelector, useAppDispatch  } from '../redux/hooks';
import {selectApi, setUpUserRole, setUpPasswordResetInfo } from '../redux/reducers/MainReducer';

import {SESSION_STORAGE_VAR, SESSION_USERROLE_VAR} from '../services/api/V1ApiService';
import { InfoModal } from "./shared/InfoModal";

interface IFormInput {
    emailAddress: string;
    password: string;
}

interface IModalState {
    header: string;
    message: string;
    success: boolean;
    open: boolean;
}

const Login = () => {
    const [modalState, setModalState] = useState<IModalState>({
        header: "",
        message: "",
        success: true,
        open: false,
      });
    const {  register,
        handleSubmit,
        formState: { errors } } = useForm<IFormInput>({ 
        mode: "onChange"
    });

    // Redux Selector
    const api = useAppSelector(selectApi);
    const dispatch = useAppDispatch();

    const history = useHistory();

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        api?.user.signIn(data.emailAddress, data.password)
        .then((res:any) => {
            if(res.error){
                setModalState({
                    header: "Login failed",
                    message:
                        "Please ensure you are not using a wrong email address or password, and try again.",
                    success: false,
                    open: true,
                });
            }
            api.setAuthToken(res.accessToken);
            dispatch(setUpUserRole(res.role))
            localStorage.setItem(SESSION_STORAGE_VAR, res.accessToken);
            localStorage.setItem(SESSION_USERROLE_VAR, res.role);
            if(!res.error) {
                if(res.temporaryPassword) {
                    dispatch(setUpPasswordResetInfo(data))
                    history.push("/reset-password");
                }else {
                    history.push("/xero2sql-products");
                }
                
            }

        }).catch((err:any) => {            
            setModalState({
                header: "Login failed",
                message:
                    "Please ensure you are not using a wrong email address or password, and try again.",
                success: false,
                open: true,
            });
            
        })
        
    };

    
    return (
        <>
            <br />
            <br />
            <InfoModal
                header={modalState.header}
                message={modalState.message}
                open={modalState.open}
                success={modalState.success}
                onOK={() => {
                    setModalState({ ...modalState, open: false });
                    modalState.success && history.push("/");
                }}
            />
            <div className="row justify-content-center">
                {/* <div className="col-lg-4 col-md-6 col-sm-8"> */}
                    <div>
                    <div className="card">
                        <div className="card-body">
                            <img src={"../assets/images/logo.png"} alt="LARASOFT"  />
                            
                            <form onSubmit={handleSubmit(onSubmit)}> 
                                <br /> 
                                <div className="form-group">
                                    <label className="text-right" htmlFor="emailAddress">Email Address</label>
                                    <input type="text"
                                        className="form-control" 
                                        id="emailAddress" 
                                        placeholder="Enter Your Email Address"
                                        {...register("emailAddress", { 
                                            required: true,
                                            pattern:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                        })} 
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.emailAddress && <p>Please enter a correct email address</p>}</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="password"                              
                                        placeholder="Enter your Password"
                                        {...register("password", { required: true})} 
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.password && <p>Please enter your correct password</p>}</small>
                                </div>
                                <br />
                                <div>
                                    <button 
                                        type="submit" 
                                        className="btn btn-lg btn-primary btn-login  mb-2" 
                                    >Log in</button>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer text-muted text-center">
                            <div className="">
                                <Link to="register" className="small text-body">Create account</Link>
                            </div>
                            <div className="">
                                <Link to="forgot-password" className="small text-body">Forgot password?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    
}

export default Login;