import { configureStore, ThunkAction, Action} from '@reduxjs/toolkit'
import mainReducer from './reducers/MainReducer';
import budget2SqlReducer from './reducers/Budget2SqlReducer';
import xero2SqlReducer from './reducers/Xero2SqlReducer';

export const store = configureStore({
  reducer: {
    main: mainReducer,
    Budget2Sql: budget2SqlReducer,
    Xero2Sql: xero2SqlReducer,
  },
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
