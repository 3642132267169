import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
// Redux Info
import { useAppSelector } from "../redux/hooks";
import { selectApi } from "../redux/reducers/MainReducer";
import { InfoModal } from "./shared/InfoModal";

interface IFormInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  repeatPassword: string;
}

interface IModalState {
  header: string;
  message: string;
  success: boolean;
  open: boolean;
}

const Register = () => {
  const [modalState, setModalState] = useState<IModalState>({
    header: "",
    message: "",
    success: true,
    open: false,
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitted, errors },
  } = useForm<IFormInput>({
    mode: "onChange",
  });

  // Redux Selector
  const api = useAppSelector(selectApi);

  const history = useHistory();

  const repeatVal = (passwordRepeat: string) =>
    passwordRepeat === getValues().password || "Passwords do not match";

  const onSubmit: SubmitHandler<IFormInput> = (data: any) => {
    api?.user
      .signUp(
        data.firstName,
        data.lastName,
        data.emailAddress,
        data.repeatPassword
      )
      .then((res: any) => {
        if (!res.error) {
          setModalState({
            header: "Success",
            message:
              "You can now proceed to log into your account. Please note: it may take some time for Larasoft to allocate your Xero2SQL products to your new account.",
            success: true,
            open: true,
          });
        } else {
            setModalState({
                header: "Registration failed",
                message:
                  "Please ensure you are not using an email address that is already registered, and try again.",
                success: false,
                open: true,
              });
        }
      })
      .catch((err: any) => {
        setModalState({
          header: "Registration failed",
          message:
            "Please ensure you are not using an email address that is already registered, and try again.",
          success: false,
          open: true,
        });
      });
  };

  return (
    <>
      <br />
      <br />
      <div className="row justify-content-center">
        <InfoModal
          header={modalState.header}
          message={modalState.message}
          open={modalState.open}
          success={modalState.success}
          onOK={() => {
            setModalState({ ...modalState, open: false });
            modalState.success && history.push("/");
          }}
        />
        <div>
          
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">LARASOFT</h3>
            
              <form onSubmit={handleSubmit(onSubmit)}>
                <br />
                <div className="form-group">
                  <label className="text-right" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="Enter Your First name"
                    {...register("firstName", { required: true })}
                  />
                </div>
                <div className="form-check">
                  <small className="form-text text-danger">
                    {errors.firstName && <p>Please enter your First Name</p>}
                  </small>
                </div>
                <div className="form-group">
                  <label className="text-right" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="test"
                    className="form-control"
                    id="lastName"
                    placeholder="Enter Your Last Name"
                    {...register("lastName", { required: true })}
                  />
                </div>
                <div className="form-check">
                  <small className="form-text text-danger">
                    {errors.lastName && <p>Please enter your Last Name</p>}
                  </small>
                </div>
                <div className="form-group">
                  <label className="text-right" htmlFor="emailAddress">
                    Email Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailAddress"
                    placeholder="Enter Your Email Address"
                    {...register("emailAddress", {
                      required: true,
                      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    })}
                  />
                </div>
                <div className="form-check">
                  <small className="form-text text-danger">
                    {errors.emailAddress && (
                      <p>Please enter a correct email address</p>
                    )}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter your Password"
                    {...register("password", { required: true, minLength: 6 })}
                  />
                </div>
                <div className="form-check">
                  <small className="form-text text-danger">
                    {errors?.password?.type === "minLength" && (
                      <p>Password is less the 6 characcter</p>
                    )}
                  </small>
                  <small className="form-text text-danger">
                    {isSubmitted
                      ? errors?.password && (
                          <p>Please enter your new password</p>
                        )
                      : ""}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="password-repeat">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password-repeat"
                    placeholder="Enter your Confirm Password"
                    {...register("repeatPassword", {
                      required: true,
                      minLength: 6,
                      validate: repeatVal,
                    })}
                  />
                </div>
                <div className="form-check">
                  <small className="form-text text-danger">
                    {(errors?.repeatPassword ||
                      errors?.repeatPassword?.type === "validate") && (
                      <p>No match with your new password</p>
                    )}
                  </small>
                </div>
                <div className="form-check">
                  <small className="form-text text-danger"></small>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-login  mb-2"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
            <div className="card-footer text-muted text-center">
              <div className="">
                <p>
                  {" "}
                  Already have an account? Please{" "}
                  <Link to="login" className="small text-body">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
