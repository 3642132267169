import {Link } from "react-router-dom";
import Logout from './Logout';

export default function Navbar() {
    return (
     <div className="container">
      <nav className="navbar navbar-expand-sm navbar-light bg-light" >
      <Link to="/xero2sql-products" className="nav-link"><div className="navbar-brand">LARASOFT</div></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ">
            <li className="nav-item active">
              <Link to="/xero2sql-products" className="nav-link">Xero2SQL</Link>
            </li>
            <li className="nav-item active">
              <Link to="/budget2sql-products" className="nav-link">Budget2SQL</Link>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto">
            <li><Logout /></li>
          </ul>
        </div>
      </nav>
    </div>
    );
};