
import { SESSION_STORAGE_VAR, SESSION_USERROLE_VAR } from '../services/api/V1ApiService';
export default function Logout() {
    const handleLogout = (e:React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();        
        localStorage.removeItem(SESSION_STORAGE_VAR);
        localStorage.removeItem(SESSION_USERROLE_VAR);
        //localStorage.clear();
        window.location.href = '/'
      };
   return(
    <nav className="navbar navbar-expand-lg navbar-light bg-light ">
    <button 
      className="btn btn-outline-success my-2 my-sm-0  ml-auto" type="submit"
      onClick={handleLogout}>Logout</button>    
    </nav>
   )
}