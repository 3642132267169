import ApiService from "./ApiService";
import UserApi from './user/UserApi';
import XeroApi from './xero/XeroApi';
import V1UserApi from './user/V1UserApi';
import V1XeroApi from './xero/V1XeroApi';
import V1BudgetApi from './budget/V1BudgetApi';

export const SESSION_STORAGE_VAR = 'user-token'
export const SESSION_USERROLE_VAR = 'user-role'

class V1ApiService implements ApiService {
  apiUrl: string;
  user: UserApi;
  xero: XeroApi;
  budget: V1BudgetApi;
  authToken: string | undefined;

  constructor(token?: string) {
    this.apiUrl = process.env.REACT_APP_API_V1_URL || '';
    this.user = new V1UserApi(this.fetchCustom)
    this.xero = new V1XeroApi(this.fetchCustom);
    this.budget = new V1BudgetApi(this.fetchCustom);
    this.authToken = token;
  }

  isAuthToken() {
    return !!this.authToken;
  }

  setAuthToken(token: string) {
    this.authToken = token;
  }

  fetchCustom = (endpoint: string, options: any): any => {
    const fullOptions = {
      ...options,
      headers: {
        ...options?.headers,
        Accept: "application/json",
        Authorization: this.authToken ? `Bearer ${this.authToken}` : undefined,
        "Content-Type": "application/json"
      }
    }

    return fetch(this.apiUrl + endpoint, fullOptions).then(res => {
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return res.json().then(result => {
          
          if(result.statusCode === 401){
            localStorage.removeItem(SESSION_STORAGE_VAR);
            localStorage.removeItem(SESSION_USERROLE_VAR);
            //window.location.href = '/'
          }
          
          if(res.status >= 300) {
            if(!result.error) {
              result.error = "Error"
            }  
          }
          return result
        })
      } else {
        return res.text().then(result => {
          return result
        })
      }
    }).catch(err => new Promise((resolve, rej) => rej(err)))

  }
}

export default V1ApiService;