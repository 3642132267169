import { createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../Store';
import ApiService from '../../services/api/ApiService';
import createApiService from '../../services/api/ApiServiceFactory';
import {SESSION_STORAGE_VAR} from '../../services/api/V1ApiService';
import {User} from "../../interfaces/User";


const tokenFromlocalStorage = localStorage.getItem(SESSION_STORAGE_VAR)||undefined;
const api = createApiService('V1', tokenFromlocalStorage);

export interface GlobalState {
  api: ApiService | undefined;
  userRole: String | undefined;
  isAuthorized: boolean;
  passwordResetInfo: {
    emailAddress:string;
    password:string
  };
  user: User[];
  userStatus: 'empty'|'completed' | 'loading';
  userLoading:boolean;
}
                                  
const initialState: GlobalState = {
  api: api,
  userRole: "",
  isAuthorized: false,
  passwordResetInfo: {
    emailAddress:"",
    password:""
  },
  user: [],
  userStatus: 'empty',
  userLoading: false,
};

export const userAsync = createAsyncThunk(
  'mainReducer/allUser',
  async()=>{      
    const response = await api?.xero
    ?.getAllUsers()
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      
    }); ;
    return response
  }
);

export const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setUpApi: state => {
      state.api = createApiService('V1');
    },

    setUpUserRole:(state, action: PayloadAction<String| undefined>)=> {
      state.userRole = action.payload
    },

    setUpPasswordResetInfo:(state, action: PayloadAction<{emailAddress:string, password:string}>)=> {
      state.passwordResetInfo.emailAddress = action.payload.emailAddress
      state.passwordResetInfo.password = action.payload.password
    },

    setUpAuthorization:(state, action: PayloadAction<boolean>)=> {
      state.isAuthorized = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userAsync.pending, (state) => {
        state.user= [];
        state.userStatus= 'loading';
        state.userLoading= true
      })
      .addCase(userAsync.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.user= action.payload;
        state.userStatus= 'completed';
        state.userLoading= false
      })
  }
});



export const { setUpApi, setUpUserRole, setUpAuthorization, setUpPasswordResetInfo} = slice.actions;

export const selectApi = (state: RootState) => state.main.api
export const selectUserRole = (state: RootState) => state.main.userRole
export const selectIsAuthorized = (state: RootState) => state.main.isAuthorized
export const selectPasswordResetInfo = (state: RootState) => state.main.passwordResetInfo
export const selectUser = (state: RootState) => state.main.user
export const selectUserStatus = (state: RootState) => state.main.userStatus
export const selectUserLoading = (state: RootState) => state.main.userLoading

export default slice.reducer; 