import { useForm, SubmitHandler } from 'react-hook-form';
// Redux Info
import { useAppSelector } from '../redux/hooks';
import {selectApi } from '../redux/reducers/MainReducer';

interface IFormInput {
    emailAddress: string;
}

const ForgotPassword = () => {
    const { register,
        handleSubmit,
        formState: { isValid, errors } } = useForm<IFormInput>({
        mode: "onChange"
    });

    // Redux Selector
    const api = useAppSelector(selectApi);

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        api?.user.forgotPassword(data.emailAddress).then((res:any) => {
            
        }).catch((err:any) => {
           
        })
    };

    return (
        <>
            <br />
            <br />
            <div className="row justify-content-center">
                <div className="">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">FORGOT PASSWORD</h3>
                            <form onSubmit={handleSubmit(onSubmit)}> 
                                <br /> 
                                <div className="form-group">
                                    <label className="text-right" htmlFor="emailAddress">Please enter your Email Address</label>
                                    <input type="text"
                                        className="form-control" 
                                        id="emailAddress"
                                        placeholder="Enter Your Email Address"
                                        {...register("emailAddress", { 
                                            required: true,
                                            pattern:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                        })}
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.emailAddress && <p>Please enter your email address</p>}</small>
                                </div>
                                <div>
                                    <button 
                                        type="submit" 
                                        className="btn btn-lg btn-primary btn-login text-uppercase mb-2"
                                        data-toggle="modal" data-target={isValid?"#forgotPasswordMessage":""} 
                                    >SUBMIT</button>
                                    {/* Modal*/}
                                    <div className="modal fade" id="forgotPasswordMessage" tabIndex={-1} role="dialog" aria-labelledby="forgotPasswordMessageLabel" aria-hidden="true">
                                        <div className="modal-dialog " role="document">
                                            <div className="modal-content">
                                                <div className="modal-header bg-secondary">
                                                    <h5 className="modal-title text-center " id="forgotPasswordMessageLabel">A RESET PASSWORK LINK WAS SENT</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>A link was send to your email address.  If the email address is in the system, you will receive a link to reset password</p>
                                                    <br /> 
                                                    <br />
                                                </div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer text-muted text-center">
                            <div className="">
                                <p className ="small">Make sure to enter the correct email address</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;