import { useEffect } from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
// Redux Info
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import {selectApi } from '../redux/reducers/MainReducer';
import {setUpFailedCreateNewBudget2sqlAreaModal,
        setUpCreateNewBudget2sqlAreaAdded,
        createNewBudget2sqlAreaAsync,
        selectCreateNewBudget2sqlAreaAdded,
        selectCreateNewBudget2sqlAreaModal,
        budgetAreaAsync} from '../redux/reducers/Budget2SqlReducer';
import { InfoModal } from "./shared/InfoModal";

interface IFormInput {
    name: string;
}

interface IBudgetCreateAreaProps {
    productId: string
}

const BudgetCreateArea = (props:IBudgetCreateAreaProps) => {
    const {  register,
        handleSubmit,
        formState: { isValid}} = useForm<IFormInput>({ 
        mode: "onChange"
    });
    
    // Redux Selector
    const api = useAppSelector(selectApi);
    const modalState = useAppSelector(selectCreateNewBudget2sqlAreaModal);
    const isAreaAdded= useAppSelector(selectCreateNewBudget2sqlAreaAdded);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(isAreaAdded){
            const id = props.productId
            dispatch(budgetAreaAsync({api, id}))
        } 
    }, [isAreaAdded]);

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        const areaName = data.name
        const productId = props.productId
        dispatch(createNewBudget2sqlAreaAsync({api, areaName, productId}))
    };

    return (
        <div>
            <InfoModal
                header={modalState.header}
                message={modalState.message}
                open={modalState.open}
                success={modalState.success}
                onOK={() => {
                    dispatch(setUpFailedCreateNewBudget2sqlAreaModal())
                }}
            />
            <button 
                hidden={!isAreaAdded}
                className ="btn btn-sm btn-primary"
                onClick = {()=> dispatch(setUpCreateNewBudget2sqlAreaAdded(false))}>Add New Area</button>
            <form onSubmit={handleSubmit(onSubmit)}> 
                <br />
                <div className="row">
                    <div className="form-group col-6">
                        <input type="text"
                            className="form-control" 
                            id="name" 
                            hidden={isAreaAdded}
                            placeholder="Enter the area name"
                            {...register("name", { required: true})} 
                        />
                    </div>
                    <div className="form-group col-2 mr-1">
                        <button 
                            type="submit" 
                            hidden={isAreaAdded}
                            className="btn btn-md btn-primary text-uppercase"
                            data-toggle="modal" data-target={isValid?"#createNewProductMessage":""} 
                        >Add</button>
                    </div>
                    <div className="form-group col-2">
                        <button 
                            className="btn btn-secondary btn-md text-uppercase"
                            hidden={isAreaAdded}
                            onClick={()=>dispatch(setUpCreateNewBudget2sqlAreaAdded(true))} 
                        >Cancel</button>
                    </div>
                </div> 
            </form>
        </div>
    )
}

export default BudgetCreateArea;