import React from "react";
import NavBar from "./NavBar";
//import Redux
import {useAppSelector, useAppDispatch } from '../redux/hooks';
import {
  selectApi
} from "../redux/reducers/MainReducer";
import {selectBudgetAllProduct,
        selectBudgetAllProductLoading, 
        selectBudgetHelperTable,
        selectBudgetArea,
        selectBudgetVersion, 
        budgetHelperTableAsync,
        budgetAreaAsync,
        budgetVersionAsync,
        linkUserToSubAreaAsync,
        linkUserToMilestoneAsync,
        linkUserToBudget2sqlProductAsync,
        linkUserToHelperTableAsync,
        selectAssignBudget2sqlLoading,
        selectAssignBudget2sqlModal,
        setUpFailedAssignBudget2sqlModal} from '../redux/reducers/Budget2SqlReducer';

import { selectUser } from "../redux/reducers/MainReducer";

import {useHistory } from "react-router-dom";

import { useForm, SubmitHandler } from 'react-hook-form';
import { InfoModal } from "./shared/InfoModal";
import Spinner from "../components/Spinner";


const SESSION_USERNAMEFORSUBAREA_VAR = 'user-name-for-subarea'
const SESSION_USERNAMEFORBUDGETPRODUCT_VAR = 'user-name-for-budget-product'
const SESSION_USERNAMEFORHELPERTABLE_VAR = 'user-name-for-helper-table'
const SESSION_USERNAMEFORMILESTONE_VAR = 'user-name-for-milestone'

interface IFormInput {
  userNameForBudgetProduct: string;
  userNameForhelperTable: string;
  userNameForSubArea: string;
  userNameForMilestone: string;
  helperTableName: string;
  productName: string;
  subAreaName: string;
  milestoneName: string
}

const customStyles = {
  textOverflow: '',
};

const localStorageUserNameForSubArea = JSON.parse(localStorage.getItem(SESSION_USERNAMEFORSUBAREA_VAR) || "1");
const localStorageUserNameForBudgetProduct = JSON.parse(localStorage.getItem(SESSION_USERNAMEFORBUDGETPRODUCT_VAR) || "1");
const localStorageUserNameForHelperTable = JSON.parse(localStorage.getItem(SESSION_USERNAMEFORHELPERTABLE_VAR) || "1");
const localStorageUserNameForMilestone = JSON.parse(localStorage.getItem(SESSION_USERNAMEFORMILESTONE_VAR) || "1");

const BudgetAssignProduct = () => {
  
  const [budgetSubAreaList, setBudgetSubAreaList] = React.useState<any[]>();
  const [budgetMilestoneList, setBudgetMilestoneList] = React.useState<any[]>();
  const {  register,
    handleSubmit,setValue } = useForm<IFormInput>({ 
    mode: "onChange"
  });

  const history = useHistory();
  // Redux Selector
  const api = useAppSelector(selectApi);
  const loading = useAppSelector(selectAssignBudget2sqlLoading);
  const modalState = useAppSelector(selectAssignBudget2sqlModal);
  const userList = useAppSelector(selectUser);
  const budgetProductList = useAppSelector(selectBudgetAllProduct);
  const budgetProductListLoading = useAppSelector(selectBudgetAllProductLoading);
  const budgetHelperTableList = useAppSelector(selectBudgetHelperTable);
  const budgetAreaList = useAppSelector(selectBudgetArea);
  const budgetVersionList = useAppSelector(selectBudgetVersion);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    budgetProductList?.map((res:any)=>{
      const id = res.Id;
      dispatch(budgetHelperTableAsync({api, id}))
      dispatch(budgetAreaAsync({api, id}))
      dispatch(budgetVersionAsync({api, id}))
     } ) 
  }, [budgetProductList]);

  React.useEffect(() => {
    let subAreaList:any=[];
    let milestoneList:any=[];
    let areaNameList:any = [];
    let versionNameList:any =[]
    budgetAreaList?.map((res:any, key:number)=>(
      areaNameList = [...areaNameList, res.Name],
      
     api?.budget?.getAllAreasSubArea(res.Id).then((res:any) => { 
       const subAreaObject = {'areaName': areaNameList[key],
                          'subAreaList': res}
       subAreaList = [...subAreaList, subAreaObject]
       setBudgetSubAreaList(subAreaList)
      }).catch((err:any) => {
        
      })
    ))

    budgetVersionList?.map((res:any, key:number)=>(
      versionNameList = [...versionNameList, res.Name],

      api?.budget?.getAllVersionsMilestone(res.Id).then((res:any) => {
        const milestoneObject = {'versionName': versionNameList[key],
                          'versionList': res}
        milestoneList = [... milestoneList, milestoneObject]
        setBudgetMilestoneList(milestoneList)
       }).catch((err:any) => {
         
       })
     ))
      
  }, [budgetAreaList, budgetVersionList]);
  
  const sortedBudgetSubAreaList = budgetSubAreaList?.sort((a, b) => {
      let fa = a.areaName.toLowerCase(),
          fb = b.areaName.toLowerCase();

      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
  });

  const sortedBudgetMilestoneList = budgetMilestoneList?.sort((a, b) => {
      let fa = a.versionName.toLowerCase(),
          fb = b.versionName.toLowerCase();

      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
  });

  const subAreaSubmit: SubmitHandler<IFormInput> = (data:any) => {
    const Id = data.userNameForSubArea
    const subareaId = data.subAreaName
    const ListOrder:any = []
    dispatch(linkUserToSubAreaAsync({api, Id, subareaId, ListOrder}))
  }

  const milestoneSubmit: SubmitHandler<IFormInput> = (data:any) => {
    const Id = data.userNameForMilestone
    const milestoneId = data.milestoneName
    const ListOrder:any = []
    dispatch(linkUserToMilestoneAsync({api, Id, milestoneId, ListOrder}))

  }

  const budgetProductSubmit: SubmitHandler<IFormInput> = (data:any) => {
    const Id = data.userNameForBudgetProduct
    const budget2SqlProductId = data.productName

    dispatch(linkUserToBudget2sqlProductAsync({api, Id, budget2SqlProductId}))
  }

  const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
    const Id = data.userNameForhelperTable
    const helperTableId = data.helperTableName
    dispatch(linkUserToHelperTableAsync({api, Id, helperTableId}))
  }
  
  return (
    <div>
      <NavBar />
      <InfoModal
          header={modalState.header}
          message={modalState.message}
          open={modalState.open}
          success={modalState.success}
          onOK={() => {
              dispatch(setUpFailedAssignBudget2sqlModal())
              modalState.success && history.push("/budget-assign-product");
              window.location.href = '/budget-assign-product'
          }}
      />
      {loading? <Spinner />:(
        <>
      <br />
      {budgetProductListLoading?<Spinner />:
      <div>
      <div className="card">
        <div className="card-header">
           <h5 className="font-weight-bold">Assign budget product to users</h5>
        </div>
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit(budgetProductSubmit)}>

          <div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`userNameForBudgetProduct`} style={{padding: 10}}>User Name</label>
              <select {...register("userNameForBudgetProduct")}
              style={customStyles}>
                <option value="">Select user name...</option>
                {userList?.map((res: any, key:any) => (
                    res.Id ===localStorageUserNameForBudgetProduct?setValue('userNameForBudgetProduct', localStorageUserNameForBudgetProduct):"",
                    <option value={res.Id} selected={res.Id ===localStorageUserNameForBudgetProduct}>{res.FirstName} {res.LastName}</option>
                ))}
              </select>
              
            </span>
          </div>

          < div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`productName`} style={{padding: 10}}>Product Name</label>
              <select {...register("productName")}>
                <option value="">Select product name...</option>
                {budgetProductList?.map((res: any, key:any) => (
                    <option value={res.Id} >{res.CompanyName}</option>
                ))}
              </select>
              
            </span>
          </div>
            <hr />
            <div>
                <button 
                    type="submit" 
                    className="btn btn-md btn-primary text-uppercase" 
                >ASSIGN</button>
            </div>
          </form>
                    
        </div>
      </div>
      <br />
      <div className="card">
        <div className="card-header">
           <h5 className="font-weight-bold">Assign sub areas to users</h5>
        </div>
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit(subAreaSubmit)}>

          <div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`userNameForSubArea`} style={{padding: 10}}>User Name</label>
              <select {...register("userNameForSubArea")}>
                <option value="">Select user name...</option>
                {userList?.map((res: any, key:any) => (
                    res.Id ===localStorageUserNameForSubArea?setValue('userNameForSubArea', localStorageUserNameForSubArea):"", 
                    <option value={res.Id} selected={res.Id ===localStorageUserNameForSubArea}>{res.FirstName} {res.LastName}</option>
                ))}
              </select>
              
            </span>
          </div>

          < div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`subAreaName`} style={{padding: 10}}>Sub Area Name</label>
              <select {...register("subAreaName")}>
                <option value="">Select sub area name...</option>
                {sortedBudgetSubAreaList?.map((res: any, key:any) => (
                    res.subAreaList.sort((a:any, b:any) => {
                        return a.ListOrder - b.ListOrder;
                    }).map((subArea:any)=> 
                    <option value={subArea.Id}>{res.areaName}-{subArea.Name}</option>
                    )))}
              </select>
              
            </span>
          </div>
            <hr />
            <div>
                <button 
                    type="submit" 
                    className="btn btn-md btn-primary text-uppercase" 
                >ASSIGN</button>
            </div>
          </form>
                    
        </div>
      </div>
      <br />
      <div className="card">
        <div className="card-header">
           <h5 className="font-weight-bold">Assign milestone to users</h5>
        </div>
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit(milestoneSubmit)}>

          <div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`userNameForMilestone`} style={{padding: 10}}>User Name</label>
              <select {...register("userNameForMilestone")}>
                <option value="">Select user name...</option>
                {userList?.map((res: any, key:any) => (
                    res.Id ===localStorageUserNameForMilestone?setValue('userNameForMilestone', localStorageUserNameForMilestone):"",
                    <option value={res.Id} selected={res.Id ===localStorageUserNameForMilestone}>{res.FirstName} {res.LastName}</option>
                ))}
              </select>
              
            </span>
          </div>

          < div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`milestoneName`} style={{padding: 10}}>Milestone Name</label>
              <select {...register("milestoneName")}>
                <option value="">Select milestone name...</option>
                {sortedBudgetMilestoneList?.map((res: any, key:any) => (
                    res.versionList.sort((a:any, b:any) => {
                      return a.ListOrder - b.ListOrder;
                  }).map((milestone:any)=> 
                    <option value={milestone.Id}>{res.versionName}-{milestone.Name}</option>
                )))}
              </select>
              
            </span>
          </div>
            <hr />
            <div>
                <button 
                    type="submit" 
                    className="btn btn-md btn-primary text-uppercase" 
                >ASSIGN</button>
            </div>
          </form>
                    
        </div>
      </div>
      <br />
      <div className="card">
        <div className="card-header">
           <h5 className="font-weight-bold">Assign helper table to users</h5>
        </div>
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit(onSubmit)}>

          <div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`userNameForhelperTable`} style={{padding: 10}}>User Name</label>
              <select {...register("userNameForhelperTable")}>
                <option value="">Select user name...</option>
                {userList?.map((res: any, key:any) => (
                    res.Id ===localStorageUserNameForHelperTable?setValue('userNameForhelperTable', localStorageUserNameForHelperTable):"",
                    <option value={res.Id} selected={res.Id ===localStorageUserNameForHelperTable}>{res.FirstName} {res.LastName}</option>
                ))}
              </select>
              
            </span>
          </div>

          < div className="input-group">
            <span className="input-group-addon">
              <label htmlFor={`helperTableName`} style={{padding: 10}}>Helper Table Name</label>
              <select {...register("helperTableName")}>
                <option value="">Select helper table name...</option>
                {budgetHelperTableList?.map((res: any, key:any) => (
                    <option value={res.Id}>{res.Name}</option>
                ))}
              </select>
              
            </span>
          </div>
            <hr />
            <div>
                <button 
                    type="submit" 
                    className="btn btn-md btn-primary text-uppercase" 
                >ASSIGN</button>
            </div>
          </form>
                    
        </div>
      </div>
      </div>
        }
      </>
      )}
      
    </div>
      
  );
}

export default BudgetAssignProduct;
