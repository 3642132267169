import { createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../Store';
import createApiService from '../../services/api/ApiServiceFactory';
import {SESSION_STORAGE_VAR} from '../../services/api/V1ApiService';
import {BudgetProduct} from "../../interfaces/Budget";
import ApiService from '../../services/api/ApiService';



const tokenFromlocalStorage = localStorage.getItem(SESSION_STORAGE_VAR)||undefined;
const SESSION_USERNAMEFORSUBAREA_VAR = 'user-name-for-subarea'
const SESSION_USERNAMEFORBUDGETPRODUCT_VAR = 'user-name-for-budget-product'
const SESSION_USERNAMEFORHELPERTABLE_VAR = 'user-name-for-helper-table'
const SESSION_USERNAMEFORMILESTONE_VAR = 'user-name-for-milestone'

export interface BudgetSqlState { 
  budgetAllProduct: BudgetProduct[];
  budgetHelperTable: any;
  budgetArea: any;
  budgetSubArea:any;
  budgetVersion:any;
  budgetMilestone:any;
  budgetAllProductStatus: 'empty'|'completed' | 'loading';
  budgetHelperTableStatus: 'empty'|'completed' | 'loading';
  budgetAreaStatus: 'empty'|'completed' | 'loading';
  budgetSubAreaStatus: 'empty'|'completed' | 'loading';
  budgetVersionStatus: 'empty'|'completed' | 'loading';
  budgetMilestoneStatus: 'empty'|'completed' | 'loading';
  budgetAllProductLoading:boolean;
  budgetHelperTableLoading:boolean;
  budgetAreaLoading:boolean;
  budgetSubAreaLoading:boolean;
  budgetVersionLoading:boolean;
  budgetMilestoneLoading:boolean;
  createNewBudget2sqlAreaModal:{
    header: string,
    message: string,
    success: boolean,
    open: boolean,
  };
  createNewBudget2sqlAreaAdded: boolean;
  createNewBudget2sqlAreaLoading: boolean;
  createNewBudget2sqlVersionModal:{
    header: string,
    message: string,
    success: boolean,
    open: boolean,
  };
  createNewBudget2sqlVersionAdded: boolean;
  createNewBudget2sqlVersionLoading: boolean;
  createNewBudget2sqlHelperTableModal:{
    header: string,
    message: string,
    success: boolean,
    open: boolean,
  };
  createNewBudget2sqlHelperTableAdded: boolean;
  createNewBudget2sqlHelperTableLoading: boolean;
  createNewBudget2sqlSubAreaModal:{
    header: string,
    message: string,
    success: boolean,
    open: boolean,
  };
  createNewBudget2sqlSubAreaAdded: boolean;
  createNewBudget2sqlSubAreaLoading: boolean;
  createNewBudget2sqlMilestoneModal:{
    header: string,
    message: string,
    success: boolean,
    open: boolean,
  };
  linkUserToSubArea: any;
  linkUserToSubAreaStatus: 'empty'|'completed' | 'loading';
  linkUserToSubAreaLoading:boolean;
  linkUserToMilestone: any;
  linkUserToMilestoneStatus: 'empty'|'completed' | 'loading';
  linkUserToMilestoneLoading:boolean;
  linkUserToBudget2sqlProduct: any;
  linkUserToBudget2sqlProductStatus: 'empty'|'completed' | 'loading';
  linkUserToBudget2sqlProductLoading:boolean;
  linkUserToHelperTable: any;
  linkUserToHelperTableStatus: 'empty'|'completed' | 'loading';
  linkUserToHelperTableLoading:boolean;
  assignBudget2sqlModal:{
    header: string,
    message: string,
    success: boolean,
    open: boolean,
  };
  createNewBudget2sqlMilestoneAdded: boolean;
  createNewBudget2sqlMilestoneLoading: boolean;
  assignBudget2sqlLoading: boolean;
}

export const budgetAllProductAsync = createAsyncThunk(
  'budget2SqlReducer/budgetAllProduct',
  async({api, adminRight}: {api:ApiService | undefined, adminRight:boolean})=>{  
    if(adminRight) {    
        const response = await api?.budget
        ?.getAllBudget2sqlProducts()
        .then((res: BudgetProduct[]) => {
          return res
        })
        .catch((err: any) => {
          return err
        });
        return response
    } else {
        const response = await api?.budget
        ?.getAllBudget2sqlProducts()
        .then((res: BudgetProduct[]) => {
          return res
        })
        .catch((err: any) => {
          return err
        });
        return response
    }
  });

export const budgetHelperTableAsync = createAsyncThunk(
  'budget2SqlReducer/budgetHelperTable',
  async({api, id}: {api:ApiService | undefined, id:any})=>{ 
    if(tokenFromlocalStorage) {     
      const response = await api?.budget
      ?.getAllBudget2sqlHelperTable(id)
      .then((res: BudgetProduct[]) => {
        return res
      })
      .catch((err: any) => {
        return err
      });
      return response 
    }}
);

export const budgetAreaAsync = createAsyncThunk(
  'budget2SqlReducer/budgetArea',
  async({api, id}: {api:ApiService | undefined, id:any})=>{   
    const response = await api?.budget
    ?.getAllBudget2sqlArea(id)
    .then((res: BudgetProduct[]) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const budgetSubAreaAsync = createAsyncThunk(
  'budget2SqlReducer/budgetSubArea',
  async({api, id}: {api:ApiService | undefined, id:any})=>{      
    const response = await api?.budget
    ?.getAllAreasSubArea(id)
    .then((res: BudgetProduct[]) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const budgetVersionAsync = createAsyncThunk(
  'budget2SqlReducer/budgetVersion',
  async({api, id}: {api:ApiService | undefined, id:any})=>{      
    const response = await api?.budget
    ?.getAllBudget2sqlVersion(id)
    .then((res: BudgetProduct[]) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const budgetMilestoneAsync = createAsyncThunk(
  'budget2SqlReducer/budgetMilestone',
  async({api, id}: {api:ApiService | undefined, id:any})=>{      
    const response = await api?.budget
    ?.getAllVersionsMilestone(id)
    .then((res: BudgetProduct[]) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }, 
);

export const createNewBudget2sqlAreaAsync = createAsyncThunk(
  'budget2SqlReducer/createNewBudget2sqlArea',
  async({api, areaName, productId}: {api:ApiService | undefined, areaName:string, productId:string})=>{      
    const response = await api?.budget?.createNewBudget2sqlArea(areaName, productId)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const createNewBudget2sqlVersionAsync = createAsyncThunk(
  'budget2SqlReducer/createNewBudget2sqlVersion',
  async({api, versionName, productId}: {api:ApiService | undefined, versionName:string, productId:string})=>{      
    const response = await api?.budget?.createNewBudget2sqlVersion(versionName, productId)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const createNewBudget2sqlHelperTableAsync = createAsyncThunk(
  'budget2SqlReducer/createNewBudget2sqlHelperTable',
  async({api, helperTableName,inputTableName, productId}: {api:ApiService | undefined, helperTableName:string, inputTableName:string, productId:string})=>{      
    const response = await api?.budget?.createNewBudget2sqlHelperTable(helperTableName, inputTableName, productId)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const createNewBudget2sqlSubAreaAsync = createAsyncThunk(
  'budget2SqlReducer/createNewBudget2sqlSubArea',
  async({api, subAreaName,listOrder, areaId}: {api:ApiService | undefined, subAreaName:string, listOrder:any, areaId:string})=>{      
    const response = await api?.budget?.createNewBudget2sqlSubArea(subAreaName, listOrder, areaId)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const createNewBudget2sqlMilestoneAsync = createAsyncThunk(
  'budget2SqlReducer/createNewBudget2sqlMilestone',
  async({api, milestoneName,listOrder, versionId}: {api:ApiService | undefined, milestoneName:string, listOrder:any, versionId:string})=>{      
    const response = await api?.budget?.createNewBudget2sqlMilestone(milestoneName, listOrder, versionId)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const linkUserToSubAreaAsync = createAsyncThunk(
  'budget2SqlReducer/linkUserToSubArea',
  async({api, Id, subareaId, ListOrder}: {api:ApiService | undefined, Id: string, subareaId:string, ListOrder:any})=>{      
    const response = await api?.budget
    ?.linkUserToSubArea(Id, subareaId, ListOrder)
    .then((res: any) => {
      localStorage.setItem(SESSION_USERNAMEFORSUBAREA_VAR, JSON.stringify(Id)); 
      return res
    })
    .catch((err: any) => {
      localStorage.setItem(SESSION_USERNAMEFORSUBAREA_VAR, JSON.stringify(Id)); 
      return err
    });
    return response
  }
);

export const linkUserToMilestoneAsync = createAsyncThunk(
  'budget2SqlReducer/linkUserToMilestone',
  async({api, Id, milestoneId, ListOrder}: {api:ApiService | undefined, Id: string, milestoneId:string, ListOrder:any})=>{      
    const response = await api?.budget
    ?.linkUserToMilestone(Id, milestoneId, ListOrder)
    .then((res: any) => {
      localStorage.setItem(SESSION_USERNAMEFORMILESTONE_VAR, JSON.stringify(Id)); 
      return res
    })
    .catch((err: any) => {
      localStorage.setItem(SESSION_USERNAMEFORMILESTONE_VAR, JSON.stringify(Id)); 
      return err
    });
    return response
  }
);

export const linkUserToBudget2sqlProductAsync = createAsyncThunk(
  'budget2SqlReducer/linkUserToBudget2sqlProduct',
  async({api, Id, budget2SqlProductId}: {api:ApiService | undefined, Id: string, budget2SqlProductId:string})=>{      
    const response = await api?.budget
    ?.linkUserToBudget2sqlProduct(Id, budget2SqlProductId)
    .then((res: any) => {
      localStorage.setItem(SESSION_USERNAMEFORBUDGETPRODUCT_VAR, JSON.stringify(Id)); 
      return res
    })
    .catch((err: any) => {
      localStorage.setItem(SESSION_USERNAMEFORBUDGETPRODUCT_VAR, JSON.stringify(Id)); 
      return err
    });
    return response
  }
);

export const linkUserToHelperTableAsync = createAsyncThunk(
  'budget2SqlReducer/linkUserToHelperTable',
  async({api, Id, helperTableId}: {api:ApiService | undefined, Id: string, helperTableId:string})=>{      
    const response = await api?.budget
    ?.linkUserToHelperTable(Id, helperTableId)
    .then((res: any) => {
      localStorage.setItem(SESSION_USERNAMEFORHELPERTABLE_VAR, JSON.stringify(Id)); 
      return res
    })
    .catch((err: any) => {
      localStorage.setItem(SESSION_USERNAMEFORHELPERTABLE_VAR, JSON.stringify(Id)); 
      return err
    });
    return response
  }
);
                                  
const initialState: BudgetSqlState = {
  budgetAllProduct: [],
  budgetHelperTable: [],
  budgetArea: [],
  budgetVersion: [],
  budgetSubArea: [],
  budgetMilestone: [],
  budgetAllProductStatus: 'empty',
  budgetHelperTableStatus: 'empty',
  budgetAreaStatus: 'empty',
  budgetSubAreaStatus: 'empty',
  budgetVersionStatus: 'empty',
  budgetMilestoneStatus: 'empty',
  budgetAllProductLoading: false,
  budgetHelperTableLoading: false,
  budgetAreaLoading: false,
  budgetSubAreaLoading: false,
  budgetVersionLoading: false,
  budgetMilestoneLoading: false,
  createNewBudget2sqlAreaModal:{
    header: "",
    message: "",
    success: true,
    open: false,
  },
  createNewBudget2sqlAreaAdded: true,
  createNewBudget2sqlAreaLoading: false,
  createNewBudget2sqlVersionModal:{
    header: "",
    message: "",
    success: true,
    open: false,
  },
  createNewBudget2sqlVersionAdded: true,
  createNewBudget2sqlVersionLoading: false,
  createNewBudget2sqlHelperTableModal:{
    header: "",
    message: "",
    success: true,
    open: false,
  },
  createNewBudget2sqlHelperTableAdded: true,
  createNewBudget2sqlHelperTableLoading: false,
  createNewBudget2sqlSubAreaModal:{
    header: "",
    message: "",
    success: true,
    open: false,
  },
  createNewBudget2sqlSubAreaAdded: true,
  createNewBudget2sqlSubAreaLoading: false,
  createNewBudget2sqlMilestoneModal:{
    header: "",
    message: "",
    success: true,
    open: false,
  },
  linkUserToSubArea:[],
  linkUserToSubAreaStatus: 'empty',
  linkUserToSubAreaLoading:false,
  linkUserToMilestone:[],
  linkUserToMilestoneStatus: 'empty',
  linkUserToMilestoneLoading:false,
  linkUserToBudget2sqlProduct:[],
  linkUserToBudget2sqlProductStatus: 'empty',
  linkUserToBudget2sqlProductLoading:false,
  linkUserToHelperTable:[],
  linkUserToHelperTableStatus: 'empty',
  linkUserToHelperTableLoading:false,
  assignBudget2sqlModal:{
    header: "",
    message: "",
    success: true,
    open: false,
  },
  createNewBudget2sqlMilestoneAdded: true,
  createNewBudget2sqlMilestoneLoading: false,
  assignBudget2sqlLoading: false
};

export const slice = createSlice({
  name: 'budget2Sql',
  initialState,
  reducers: {
    setUpFailedCreateNewBudget2sqlAreaModal:(state)=> {
      state.createNewBudget2sqlAreaModal={
        header: " Adding new area failed",
        message:
            `Please try again. This area exist already`,
        success: false,
        open: false,
      }
    },
    setUpCreateNewBudget2sqlAreaAdded:(state, action: PayloadAction<boolean>)=> {
      state.createNewBudget2sqlAreaAdded = action.payload
    },
    setUpFailedCreateNewBudget2sqlVersionModal:(state)=> {
      state.createNewBudget2sqlVersionModal={
        header: " Adding new Version failed",
        message:
            `Please try again. This Version exist already`,
        success: false,
        open: false,
      }
    },
    setUpCreateNewBudget2sqlVersionAdded:(state, action: PayloadAction<boolean>)=> {
      state.createNewBudget2sqlVersionAdded = action.payload
    },
    setUpFailedCreateNewBudget2sqlHelperTableModal:(state)=> {
      state.createNewBudget2sqlHelperTableModal={
        header: " Adding new HelperTable failed",
        message:
            `Please try again. This HelperTable exist already`,
        success: false,
        open: false,
      }
    },
    setUpCreateNewBudget2sqlHelperTableAdded:(state, action: PayloadAction<boolean>)=> {
      state.createNewBudget2sqlHelperTableAdded = action.payload
    },
    setUpFailedCreateNewBudget2sqlSubAreaModal:(state)=> {
      state.createNewBudget2sqlSubAreaModal={
        header: " Adding new SubArea failed",
        message:
            `Please try again. This SubArea exist already`,
        success: false,
        open: false,
      }
    },
    setUpCreateNewBudget2sqlSubAreaAdded:(state, action: PayloadAction<boolean>)=> {
      state.createNewBudget2sqlSubAreaAdded = action.payload
    },

    setUpFailedCreateNewBudget2sqlMilestoneModal:(state)=> {
      state.createNewBudget2sqlMilestoneModal={
        header: " Adding new Milestone failed",
        message:
            `Please try again. This Milestone exist already`,
        success: false,
        open: false,
      }
    },
    setUpCreateNewBudget2sqlMilestoneAdded:(state, action: PayloadAction<boolean>)=> {
      state.createNewBudget2sqlMilestoneAdded = action.payload
    },
    setUpFailedAssignBudget2sqlModal:(state)=> {
      state.assignBudget2sqlModal={
        header: " Adding new failed",
        message: "Please try again.",
        success: false,
        open: false,
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(budgetAllProductAsync.pending, (state) => {
        state.budgetAllProduct= [];
        state.budgetAllProductStatus= 'loading';
        state.budgetAllProductLoading= true
      })
      .addCase(budgetAllProductAsync.fulfilled, (state, action: PayloadAction<BudgetProduct[]>) => {
        state.budgetAllProduct= action.payload;
        state.budgetAllProductStatus= 'completed';
        state.budgetAllProductLoading= false
      })
      .addCase(budgetHelperTableAsync.pending, (state) => {
        state.budgetHelperTable= [];
        state.budgetHelperTableStatus= 'loading';
        state.budgetHelperTableLoading= true
      })
      .addCase(budgetHelperTableAsync.fulfilled, (state, action) => {
        state.budgetHelperTable= action.payload;
        state.budgetHelperTableStatus= 'completed';
        state.budgetHelperTableLoading= false
      })
      .addCase(budgetAreaAsync.pending, (state) => {
        state.budgetArea= [];
        state.budgetAreaStatus= 'loading';
        state.budgetAreaLoading= true
      })
      .addCase(budgetAreaAsync.fulfilled, (state, action) => {
        state.budgetArea= action.payload;
        state.budgetAreaStatus= 'completed';
        state.budgetAreaLoading= false
      })
      .addCase(budgetVersionAsync.pending, (state) => {
        state.budgetVersion= [];
        state.budgetVersionStatus= 'loading';
        state.budgetVersionLoading= true
      })
      .addCase(budgetVersionAsync.fulfilled, (state, action) => {
        state.budgetVersion= action.payload;
        state.budgetVersionStatus= 'completed';
        state.budgetVersionLoading= false
      })
      .addCase(budgetSubAreaAsync.pending, (state) => {
        state.budgetSubArea= [];
        state.budgetSubAreaStatus= 'loading';
        state.budgetSubAreaLoading= true
      })
      .addCase(budgetSubAreaAsync.fulfilled, (state, action) => {
        state.budgetSubArea= action.payload;
        state.budgetSubAreaStatus= 'completed';
        state.budgetSubAreaLoading= false
      })
      .addCase(budgetMilestoneAsync.pending, (state) => {
        state.budgetMilestone= [];
        state.budgetMilestoneStatus= 'loading';
        state.budgetMilestoneLoading= true
      })
      .addCase(budgetMilestoneAsync.fulfilled, (state, action) => {
        state.budgetMilestone= action.payload;
        state.budgetMilestoneStatus= 'completed';
        state.budgetMilestoneLoading= false
      })
      .addCase(createNewBudget2sqlAreaAsync.pending, (state) => {
        state.createNewBudget2sqlAreaLoading= true
        state.createNewBudget2sqlAreaAdded= false
      })
      .addCase(createNewBudget2sqlAreaAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.createNewBudget2sqlAreaLoading= false
        state.createNewBudget2sqlAreaAdded= true
        if(action.payload.error || action.payload.statusCode){
          state.createNewBudget2sqlAreaModal={
            header: " Adding new area failed",
            message:
                `Please try again. This area exist already`,
            success: false,
            open: true,
          }
        }else {
          state.createNewBudget2sqlAreaModal={
            header: "Success",
            message:
                "Adding new area succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(createNewBudget2sqlVersionAsync.pending, (state) => {
        state.createNewBudget2sqlVersionLoading= true
        state.createNewBudget2sqlVersionAdded= false
      })
      .addCase(createNewBudget2sqlVersionAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.createNewBudget2sqlVersionLoading= false
        state.createNewBudget2sqlVersionAdded= true
        if(action.payload.error || action.payload.statusCode){
          state.createNewBudget2sqlVersionModal={
            header: " Adding new Version failed",
            message:
                `Please try again. This Version exist already`,
            success: false,
            open: true,
          }
        }else {
          state.createNewBudget2sqlVersionModal={
            header: "Success",
            message:
                "Adding new Version succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(createNewBudget2sqlHelperTableAsync.pending, (state) => {
        state.createNewBudget2sqlHelperTableLoading= true
        state.createNewBudget2sqlHelperTableAdded= false
      })
      .addCase(createNewBudget2sqlHelperTableAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.createNewBudget2sqlHelperTableLoading= false
        state.createNewBudget2sqlHelperTableAdded= true
        if(action.payload.error || action.payload.statusCode){
          state.createNewBudget2sqlHelperTableModal={
            header: " Adding new HelperTable failed",
            message:
                `Please try again. This HelperTable exist already`,
            success: false,
            open: true,
          }
        }else {
          state.createNewBudget2sqlHelperTableModal={
            header: "Success",
            message:
                "Adding new HelperTable succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(createNewBudget2sqlSubAreaAsync.pending, (state) => {
        state.createNewBudget2sqlSubAreaLoading= true
        state.createNewBudget2sqlSubAreaAdded= false
      })
      .addCase(createNewBudget2sqlSubAreaAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.createNewBudget2sqlSubAreaLoading= false
        state.createNewBudget2sqlSubAreaAdded= true
        if(action.payload.error || action.payload.statusCode){
          state.createNewBudget2sqlSubAreaModal={
            header: " Adding new SubArea failed",
            message:
                `Please try again. This SubArea exist already`,
            success: false,
            open: true,
          }
        }else {
          state.createNewBudget2sqlSubAreaModal={
            header: "Success",
            message:
                "Adding new SubArea succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(createNewBudget2sqlMilestoneAsync.pending, (state) => {
        state.createNewBudget2sqlMilestoneLoading= true
        state.createNewBudget2sqlMilestoneAdded= false
      })
      .addCase(createNewBudget2sqlMilestoneAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.createNewBudget2sqlMilestoneLoading= false
        state.createNewBudget2sqlMilestoneAdded= true
        if(action.payload.error || action.payload.statusCode){
          state.createNewBudget2sqlMilestoneModal={
            header: " Adding new Milestone failed",
            message:
                `Please try again. This Milestone exist already`,
            success: false,
            open: true,
          }
        }else {
          state.createNewBudget2sqlMilestoneModal={
            header: "Success",
            message:
                "Adding new Milestone succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(linkUserToSubAreaAsync.pending, (state) => {
        state.linkUserToSubArea= [];
        state.linkUserToSubAreaStatus= 'loading';
        state.linkUserToSubAreaLoading= true;
        state.assignBudget2sqlLoading = true
      })
      .addCase(linkUserToSubAreaAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.linkUserToSubArea= action.payload;
        state.linkUserToSubAreaStatus= 'completed';
        state.linkUserToSubAreaLoading= false;
        state.assignBudget2sqlLoading = false
        if(action.payload.error){
          state.assignBudget2sqlModal={
            header: " Adding new SubArea failed",
            message: "Please try again. This sub area is already assigned to this user",
            success: false,
            open: true,
          }
        }else {
          state.assignBudget2sqlModal={
            header: " Success ",
            message: "Adding new subarea succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(linkUserToMilestoneAsync.pending, (state) => {
        state.linkUserToMilestone= [];
        state.linkUserToMilestoneStatus= 'loading';
        state.linkUserToMilestoneLoading= true;
        state.assignBudget2sqlLoading = true
      })
      .addCase(linkUserToMilestoneAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.linkUserToMilestone= action.payload;
        state.linkUserToMilestoneStatus= 'completed';
        state.linkUserToMilestoneLoading= false;
        state.assignBudget2sqlLoading = false
        if(action.payload.error){
          state.assignBudget2sqlModal={
            header: " Adding new Milestone failed",
            message: "Please try again. This milestone is already assigned to this user",
            success: false,
            open: true,
          }
        }else {
          state.assignBudget2sqlModal={
            header: " Success ",
            message: "Adding new Milestone succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(linkUserToBudget2sqlProductAsync.pending, (state) => {
        state.linkUserToBudget2sqlProduct= [];
        state.linkUserToBudget2sqlProductStatus= 'loading';
        state.linkUserToBudget2sqlProductLoading= true;
        state.assignBudget2sqlLoading = true
      })
      .addCase(linkUserToBudget2sqlProductAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.linkUserToBudget2sqlProduct= action.payload;
        state.linkUserToBudget2sqlProductStatus= 'completed';
        state.linkUserToBudget2sqlProductLoading= false;
        state.assignBudget2sqlLoading = false
        if(action.payload.error){
          state.assignBudget2sqlModal={
            header: " Adding new Budget2sqlProduct failed",
            message: "Please try again. This Budget2sqlProduct is already assigned to this user",
            success: false,
            open: true,
          }
        }else {
          state.assignBudget2sqlModal={
            header: " Success ",
            message: "Adding new Budget2sqlProduct succeed",
            success: true,
            open: true,
          }
        }
      })
      .addCase(linkUserToHelperTableAsync.pending, (state) => {
        state.linkUserToHelperTable= [];
        state.linkUserToHelperTableStatus= 'loading';
        state.linkUserToHelperTableLoading= true;
        state.assignBudget2sqlLoading = true
      })
      .addCase(linkUserToHelperTableAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.linkUserToHelperTable= action.payload;
        state.linkUserToHelperTableStatus= 'completed';
        state.linkUserToHelperTableLoading= false;
        state.assignBudget2sqlLoading = false
        if(action.payload.error){
          state.assignBudget2sqlModal={
            header: " Adding new HelperTable failed",
            message: "Please try again. This HelperTable is already assigned to this user",
            success: false,
            open: true,
          }
        }else {
          state.assignBudget2sqlModal={
            header: " Success ",
            message: "Adding new HelperTable succeed",
            success: true,
            open: true,
          }
        }
      })
  }
});

export const {setUpFailedCreateNewBudget2sqlAreaModal,
              setUpCreateNewBudget2sqlAreaAdded,
              setUpFailedCreateNewBudget2sqlVersionModal,
              setUpCreateNewBudget2sqlVersionAdded,
              setUpFailedCreateNewBudget2sqlHelperTableModal,
              setUpCreateNewBudget2sqlHelperTableAdded,
              setUpFailedCreateNewBudget2sqlSubAreaModal,
              setUpCreateNewBudget2sqlSubAreaAdded,
              setUpFailedCreateNewBudget2sqlMilestoneModal,
              setUpCreateNewBudget2sqlMilestoneAdded,
              setUpFailedAssignBudget2sqlModal,} = slice.actions;

export const selectBudgetAllProduct = (state: RootState) => state.Budget2Sql.budgetAllProduct
export const selectBudgetAllProductStatus = (state: RootState) => state.Budget2Sql.budgetAllProductStatus
export const selectBudgetAllProductLoading = (state: RootState) => state.Budget2Sql.budgetAllProductLoading
export const selectBudgetHelperTable = (state: RootState) => state.Budget2Sql.budgetHelperTable
export const selectBudgetHelperTableStatus = (state: RootState) => state.Budget2Sql.budgetHelperTableStatus
export const selectBudgetHelperTableLoading = (state: RootState) => state.Budget2Sql.budgetHelperTableLoading
export const selectBudgetArea = (state: RootState) => state.Budget2Sql.budgetArea
export const selectBudgetAreaStatus = (state: RootState) => state.Budget2Sql.budgetAreaStatus
export const selectBudgetAreaLoading = (state: RootState) => state.Budget2Sql.budgetAreaLoading
export const selectBudgetVersion = (state: RootState) => state.Budget2Sql.budgetVersion
export const selectBudgetVersionStatus = (state: RootState) => state.Budget2Sql.budgetVersionStatus
export const selectBudgetVersionLoading = (state: RootState) => state.Budget2Sql.budgetVersionLoading
export const selectBudgetSubArea = (state: RootState) => state.Budget2Sql.budgetSubArea
export const selectBudgetSubAreaStatus = (state: RootState) => state.Budget2Sql.budgetSubAreaStatus
export const selectBudgetSubAreaLoading = (state: RootState) => state.Budget2Sql.budgetSubAreaLoading
export const selectBudgetMilestone = (state: RootState) => state.Budget2Sql.budgetMilestone
export const selectBudgetMilestoneStatus = (state: RootState) => state.Budget2Sql.budgetMilestoneStatus
export const selectBudgetMilestoneLoading = (state: RootState) => state.Budget2Sql.budgetMilestoneLoading
export const selectCreateNewBudget2sqlAreaAdded = (state: RootState) => state.Budget2Sql.createNewBudget2sqlAreaAdded
export const selectCreateNewBudget2sqlAreaLoading = (state: RootState) => state.Budget2Sql.createNewBudget2sqlAreaLoading
export const selectCreateNewBudget2sqlAreaModal = (state: RootState) => state.Budget2Sql.createNewBudget2sqlAreaModal
export const selectCreateNewBudget2sqlVersionAdded = (state: RootState) => state.Budget2Sql.createNewBudget2sqlVersionAdded
export const selectCreateNewBudget2sqlVersionLoading = (state: RootState) => state.Budget2Sql.createNewBudget2sqlVersionLoading
export const selectCreateNewBudget2sqlVersionModal = (state: RootState) => state.Budget2Sql.createNewBudget2sqlVersionModal
export const selectCreateNewBudget2sqlHelperTableAdded = (state: RootState) => state.Budget2Sql.createNewBudget2sqlHelperTableAdded
export const selectCreateNewBudget2sqlHelperTableLoading = (state: RootState) => state.Budget2Sql.createNewBudget2sqlHelperTableLoading
export const selectCreateNewBudget2sqlHelperTableModal = (state: RootState) => state.Budget2Sql.createNewBudget2sqlHelperTableModal
export const selectCreateNewBudget2sqlSubAreaAdded = (state: RootState) => state.Budget2Sql.createNewBudget2sqlSubAreaAdded
export const selectCreateNewBudget2sqlSubAreaLoading = (state: RootState) => state.Budget2Sql.createNewBudget2sqlSubAreaLoading
export const selectCreateNewBudget2sqlSubAreaModal = (state: RootState) => state.Budget2Sql.createNewBudget2sqlSubAreaModal
export const selectCreateNewBudget2sqlMilestoneAdded = (state: RootState) => state.Budget2Sql.createNewBudget2sqlMilestoneAdded
export const selectCreateNewBudget2sqlMilestoneLoading = (state: RootState) => state.Budget2Sql.createNewBudget2sqlMilestoneLoading
export const selectCreateNewBudget2sqlMilestoneModal = (state: RootState) => state.Budget2Sql.createNewBudget2sqlMilestoneModal
export const selectAssignBudget2sqlModal = (state: RootState) => state.Budget2Sql.assignBudget2sqlModal
export const selectAssignBudget2sqlLoading = (state: RootState) => state.Budget2Sql.assignBudget2sqlLoading


export default slice.reducer; 