import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";
// Redux Info
import { useAppSelector } from '../redux/hooks';
import {selectApi, selectPasswordResetInfo } from '../redux/reducers/MainReducer';
import { InfoModal } from "./shared/InfoModal";

interface IFormInput {
    password: string;
    repeatPassword: string;
}

interface IModalState {
    header: string;
    message: string;
    success: boolean;
    open: boolean;
} 

const ResetPassword= () => {
    
    const [modalState, setModalState] = useState<IModalState>({
        header: "",
        message: "",
        success: true,
        open: false,
      });

    const {  register,
        handleSubmit,
        getValues,
        formState: {isSubmitted, errors } } = useForm<IFormInput>({ 
        mode: "onChange"
    });

    const history = useHistory();

    // Redux Selector
    const api = useAppSelector(selectApi);
    const resetPasswordInfo = useAppSelector(selectPasswordResetInfo);

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        api?.user
            .resetPassword(
                resetPasswordInfo.emailAddress, 
                resetPasswordInfo.password,
                data.password
            ).then((res:any) => {
                if (!res.error) {
                    setModalState({
                        header: "Success",
                        message:
                            "You can now proceed to log into your account with the new password.",
                        success: true,
                        open: true,
                    })
                }
            }).catch((err:any) => {

                setModalState({
                    header: "Password Change failed",
                    message:
                      "Please ensure you are not using the  correct temporary password, and try again.",
                    success: false,
                    open: true,
                });
            
            })
    };

    const repeatVal = (passwordRepeat:string)=> passwordRepeat === getValues().password || "Passwords do not match";
    return (
        <>
            <br />
            <InfoModal
                header={modalState.header}
                message={modalState.message}
                open={modalState.open}
                success={modalState.success}
                onOK={() => {
                    setModalState({ ...modalState, open: false });
                    modalState.success && history.push("/");
                }}
            />
            <br />
            <div className="row justify-content-center">
                <div className="">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">RESET PASSWORD</h3>
                            <form onSubmit={handleSubmit(onSubmit)}> 
                                <br /> 
                                <div className="form-group">
                                    <label htmlFor="password">New Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="password"                              
                                        placeholder="Enter your New Password"
                                        {...register("password", { 
                                            required: true,
                                            validate: repeatVal
                                        })}
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors?.password?.type === "validate" && (<p>No match with your new password</p>)}</small>
                                    <small className="form-text text-danger">{isSubmitted?errors.password?.type === "required"&& <p>Please enter your new password</p>:""}</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="repeat-password">Confirm New Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="repeat-password"                              
                                        placeholder="Confirm New Password"
                                        {...register("repeatPassword", {
                                            required: true,
                                            validate: repeatVal
                                          })}
                                        
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors?.repeatPassword?.type === "validate" && (<p>No match with your new password</p>)}</small>
                                    <small className="form-text text-danger">{isSubmitted? errors?.repeatPassword?.type === "required"&& <p>Please enter your new password</p>:''}</small>
                                </div>
                                <br />
                                <div>
                                    <button 
                                        type="submit" 
                                        className="btn btn-lg btn-primary btn-login text-uppercase mb-2"
                                    >RESET</button>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer text-muted text-center">
                            <div className="">
                             <p>
                                 Please enter your new password
                             </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    
}

export default ResetPassword;