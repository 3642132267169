import { createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../Store';
import {XeroProduct} from "../../interfaces/Xero";
import ApiService from '../../services/api/ApiService';


export interface Xero2SqlState { 
  xeroAllProduct: XeroProduct[];
  xeroAllProductStatus: 'empty'|'completed' | 'loading';
  xeroAllProductLoading:boolean;
  createNewXero2sqlProduct: XeroProduct[];
  createNewXero2sqlProductStatus: 'empty'|'completed' | 'loading';
  createNewXero2sqlProductLoading:boolean;
  createNewXero2sqlProductError:boolean;
  linkUserToXero2sqlProduct: XeroProduct[];
  linkUserToXero2sqlProductStatus: 'empty'|'completed' | 'loading';
  linkUserToXero2sqlProductLoading:boolean;
  linkUserToXero2sqlProductModal:{
                                  header: string,
                                  message: string,
                                  success: boolean,
                                  open: boolean,
                                };
  xeroIncrementalRunXero2sqlProductLoading:boolean;
  xeroIncrementalRunXero2sqlProductModal:boolean;
  xeroIncrementalRunXero2sqlProductErrorModal:boolean;
}


export const xeroAllProductAsync = createAsyncThunk(
  'xero2SqlReducer/xeroAllProduct',
  async({api, adminRight}: {api:ApiService | undefined, adminRight:boolean})=>{  
    if(adminRight) {
      const response = await api?.xero
      ?.getAllProducts()
      .then((res: XeroProduct[]) => {
        return res
      })
      .catch((err: any) => {
        return err
      });
      return response
   } else {
      const response = await api?.xero
      ?.getAllXero2sqlProducts()
      .then((res: XeroProduct[]) => {
        return res
      })
      .catch((err: any) => {
        return err
      });
      return response
   }
  }
);

export const incrementalRunXero2sqlProductAsync = createAsyncThunk(
  'xero2SqlReducer/incrementalRunXero2sqlProduct',
  async({api, id}: {api:ApiService | undefined, id:number})=>{      
    const response = await api?.xero
    ?.incrementalRunXero2sqlProduct(id)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const linkUserToXero2sqlProductAsync = createAsyncThunk(
  'xero2SqlReducer/linkUserToXero2sqlProduct',
  async({api, Id, xero2SqlProductId}: {api:ApiService | undefined, Id: string, xero2SqlProductId:string})=>{      
    const response = await api?.xero
    ?.linkUserToXero2sqlProduct(Id, xero2SqlProductId)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);

export const createNewXero2sqlProductAsync = createAsyncThunk(
  'xero2SqlReducer/createNewXero2sqlProduct',
  async({api, data}: {api:ApiService | undefined, data:any})=>{      
    const response = await api?.xero
    ?.createNewXero2sqlProduct(data)
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      return err
    });
    return response
  }
);
                                  
const initialState: Xero2SqlState = {
  xeroAllProduct: [],
  xeroAllProductStatus: 'empty',
  xeroAllProductLoading:false,
  createNewXero2sqlProduct: [],
  createNewXero2sqlProductStatus: 'empty',
  createNewXero2sqlProductLoading:false,
  createNewXero2sqlProductError:false,
  linkUserToXero2sqlProduct:[],
  linkUserToXero2sqlProductStatus: 'empty',
  linkUserToXero2sqlProductLoading:false,
  linkUserToXero2sqlProductModal:{
                                    header: "",
                                    message: "",
                                    success: true,
                                    open: false,
                                  },
  xeroIncrementalRunXero2sqlProductLoading:false,
  xeroIncrementalRunXero2sqlProductModal:false,
  xeroIncrementalRunXero2sqlProductErrorModal:false,
};

export const slice = createSlice({
  name: 'xero2Sql',
  initialState,
  reducers: {
    setUpCloseXeroIncrementalRunXero2sqlProductModal:(state, action: PayloadAction<boolean>)=> {
      state.xeroIncrementalRunXero2sqlProductModal = action.payload
    },
    setUpCloseXeroIncrementalRunXero2sqlProductErrorModal:(state, action: PayloadAction<boolean>)=> {
      state.xeroIncrementalRunXero2sqlProductErrorModal = action.payload
    },

    setUpLinkUserToXero2sqlProductModal:(state)=> {
      state.linkUserToXero2sqlProductModal={
        header: " Adding new product failed",
        message: "Please try again.",
        success: false,
        open: false,
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(xeroAllProductAsync.pending, (state) => {
        state.xeroAllProduct= [];
        state.xeroAllProductStatus= 'loading';
        state.xeroAllProductLoading= true
      })
      .addCase(xeroAllProductAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.xeroAllProductStatus= 'completed';
        state.xeroAllProductLoading= false

        if(action.payload.error){
          state.xeroAllProduct= [];
        }else{
          state.xeroAllProduct= action.payload;
        }
      })
      .addCase(incrementalRunXero2sqlProductAsync.pending, (state) => {
        state.xeroIncrementalRunXero2sqlProductLoading= true;
        
      })
      .addCase(incrementalRunXero2sqlProductAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.xeroIncrementalRunXero2sqlProductLoading= false;
        if(action.payload.error) {
          state.xeroIncrementalRunXero2sqlProductErrorModal=true
        }else{
          state.xeroIncrementalRunXero2sqlProductModal= true
          // take existing products, find the one that was changed, replace it
          const newProducts = state.xeroAllProduct ?  [...state.xeroAllProduct] : [];
          const newIndex = newProducts.findIndex((n) => n.Id === action.payload.Id);
          newProducts[newIndex] = action.payload;
          //state.xeroAllProduct = newProducts;
        }
        
      })
      .addCase(createNewXero2sqlProductAsync.pending, (state) => {
        state.createNewXero2sqlProduct= [];
        state.createNewXero2sqlProductStatus= 'loading';
        state.createNewXero2sqlProductLoading= true
      })
      .addCase(createNewXero2sqlProductAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.createNewXero2sqlProduct= action.payload;
        state.createNewXero2sqlProductStatus= 'completed';
        state.createNewXero2sqlProductLoading= false
        if(action.payload.error || action.payload.statusCode===500){
          state.createNewXero2sqlProductError = true
        }else{
          state.createNewXero2sqlProductError = false
        }
      })
      .addCase(linkUserToXero2sqlProductAsync.pending, (state) => {
        state.linkUserToXero2sqlProduct= [];
        state.linkUserToXero2sqlProductStatus= 'loading';
        state.linkUserToXero2sqlProductLoading= true
      })
      .addCase(linkUserToXero2sqlProductAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.linkUserToXero2sqlProduct= action.payload;
        state.linkUserToXero2sqlProductStatus= 'completed';
        state.linkUserToXero2sqlProductLoading= false
        if(action.payload.error){
          state.linkUserToXero2sqlProductModal={
            header: " Adding new product failed",
            message: "Please try again.",
            success: false,
            open: true,
          }
        }else {
          state.linkUserToXero2sqlProductModal={
            header: " Success ",
            message: "New product was assigned",
            success: true,
            open: true,
          }
        }
      })
  }
});

export const { setUpCloseXeroIncrementalRunXero2sqlProductModal,
              setUpCloseXeroIncrementalRunXero2sqlProductErrorModal,
              setUpLinkUserToXero2sqlProductModal} = slice.actions;

export const selectXeroAllProduct = (state: RootState) => state.Xero2Sql.xeroAllProduct
export const selectXeroAllProductStatus = (state: RootState) => state.Xero2Sql.xeroAllProductStatus
export const selectXeroAllProductLoading = (state: RootState) => state.Xero2Sql.xeroAllProductLoading
export const selectCreateNewXero2sqlProduct = (state: RootState) => state.Xero2Sql.createNewXero2sqlProduct
export const selectCreateNewXero2sqlProductStatus = (state: RootState) => state.Xero2Sql.createNewXero2sqlProductStatus
export const selectCreateNewXero2sqlProductLoading = (state: RootState) => state.Xero2Sql.createNewXero2sqlProductLoading
export const selectCreateNewXero2sqlProductError = (state: RootState) => state.Xero2Sql.createNewXero2sqlProductError
export const selectLinkUserToXero2sqlProductModal = (state: RootState) => state.Xero2Sql.linkUserToXero2sqlProductModal
export const selectXeroIncrementalRunXero2sqlProductModal = (state: RootState) => state.Xero2Sql.xeroIncrementalRunXero2sqlProductModal
export const selectXeroIncrementalRunXero2sqlProductErrorModal = (state: RootState) => state.Xero2Sql.xeroIncrementalRunXero2sqlProductErrorModal
export const selectXeroIncrementalRunXero2sqlProductLoading = (state: RootState) => state.Xero2Sql.xeroIncrementalRunXero2sqlProductLoading



export default slice.reducer; 