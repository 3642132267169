import React from 'react'

const Spinner = () => {  
  
  return (
    <div className="d-flex justify-content-center">
      
        <div className="spinner-border text-primary justify-content-center " style={{width: 100, height: 100}} role="status">
            <span className="sr-only"></span>
        </div>
    </div>
    
  )
}

export default Spinner;