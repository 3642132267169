import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxWidth: "360px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface IModalProps {
  header: string;
  message: string;
  open: boolean;
  success: boolean;
  onOK: () => void;
}

export const InfoModal: React.FC<IModalProps> = (props: IModalProps) => {
  return (
    <Modal
      isOpen={props.open}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="text-center">
        <div className={props.success ? "card-header bg-success" : "card-header bg-danger"} >
          <h2 className= 'font-weight-bold'>{props.header}</h2>
        </div>
        
        <div className="card-body">
          <p>{props.message}</p>
          <button
            className="btn btn-lg btn-primary btn-login  mb-2"
            onClick={() => {
              props.onOK();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};
