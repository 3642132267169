import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// Redux Info
import { useAppSelector, useAppDispatch} from "../redux/hooks";
import {
  selectApi,
  selectUserRole,
  userAsync
} from "../redux/reducers/MainReducer";
import {selectXeroAllProduct, 
        selectXeroAllProductLoading,
        xeroAllProductAsync,
        incrementalRunXero2sqlProductAsync,
        selectXeroIncrementalRunXero2sqlProductModal,
        selectXeroIncrementalRunXero2sqlProductErrorModal,
        setUpCloseXeroIncrementalRunXero2sqlProductModal,
        setUpCloseXeroIncrementalRunXero2sqlProductErrorModal,
        selectXeroIncrementalRunXero2sqlProductLoading} from '../redux/reducers/Xero2SqlReducer';
import NavBar from "../components/NavBar";
import Spinner from "../components/Spinner";
import { SESSION_USERROLE_VAR } from "../services/api/V1ApiService";
import { FaSyncAlt, FaPlusSquare, FaUsersCog } from "react-icons/fa";
import { IconContext } from "react-icons";
import { InfoModal } from "./shared/InfoModal";

const userRoleFromlocalStorage =
  localStorage.getItem(SESSION_USERROLE_VAR) || undefined;

const XeroProductList = () => {

  // Redux Selector
  const api = useAppSelector(selectApi);
  const userRole = useAppSelector(selectUserRole);
  const xeroProductList = useAppSelector(selectXeroAllProduct);
  const incrementalRunLoading = useAppSelector(selectXeroIncrementalRunXero2sqlProductLoading);
  const loading = useAppSelector(selectXeroAllProductLoading);
  const modalOpen = useAppSelector(selectXeroIncrementalRunXero2sqlProductModal);
  const errorModalOpen = useAppSelector(selectXeroIncrementalRunXero2sqlProductErrorModal);

  const dispatch = useAppDispatch();

  const adminRight = userRole === "SuperAdmin" || userRoleFromlocalStorage === "SuperAdmin"

  useEffect(() => {
    dispatch(xeroAllProductAsync({api, adminRight}))
    dispatch(userAsync())
    const timer = setInterval(() => {
      dispatch(xeroAllProductAsync({api, adminRight})) 
    }, 60000);

    return () => {
      clearInterval(timer);
    };
    
  }, []);
  

  const incrementRun = (id: any) => {
    dispatch(incrementalRunXero2sqlProductAsync({api, id}))
  };

  // 3 states here can be useful - before loaded xeroProductList is undefined, then after loaded it is either [] or has products
  // means we can know - still loading, loaded but no products, loaded with >0 products
  if (xeroProductList && !xeroProductList.length) {
    return (
      <div>
        <NavBar /> 
        <br />
        <br />
      { loading?<Spinner />:(
        <div>
          <br />
          <h2>No products registered</h2>
          <p>Please contact Larasoft in order to link your Xero2SQL Entities to your portal account.</p>
        </div>
      )}
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <InfoModal
        header="Sync Started"
        message="Your sync has started successfully. Typically this will take 10-15 minutes to finish."
        open={modalOpen}
        success={true}
        onOK={() => dispatch(setUpCloseXeroIncrementalRunXero2sqlProductModal(false))}
      />
      <InfoModal
        header="Sync Failed"
        message="Your sync has failed. Please ensure you wait 15 minutes from the previous start time"
        open={errorModalOpen}
        success={false}
        onOK={() => dispatch(setUpCloseXeroIncrementalRunXero2sqlProductErrorModal(false))}
      />
      <div className="row">
        <h2 className="col-8 text-center">My Entities</h2>
        {adminRight ? (
          <div className="col-4">
            <Link to="xero2sql-create-product" className="small text-body">
              <IconContext.Provider
                value={{ size: "40px", className: "text-primary text-right" }}
              >
                <span
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Incremental Sync"
                >
                  <FaPlusSquare />
                </span>
              </IconContext.Provider>
            </Link>
            <Link to="assign-product" className="small text-body">
              <IconContext.Provider
                value={{ size: "40px", className: "text-primary text-right" }}
              >
                <span
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Link user to a product"
                >
                  <FaUsersCog />
                </span>
              </IconContext.Provider>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Entity Name</th>
              <th>Last Sync Start</th>
            </tr>
          </thead>
          <br />
          <tbody>
            {xeroProductList?.map((res: any) => (
                <tr>
                  <td className="row">
                    <div className="col-10">{res.CompanyName}</div>
                    <div className="col-2">
                      <IconContext.Provider
                        value={{
                          size: "20px",
                          className: "text-success text-right",
                        }}
                      >
                        {/* {res.Id === syncLoader ? ( */}
                        {moment().diff(
                          moment(res.LastIncrementalTriggerDate),
                          "minutes"
                        ) < 10 ? (
                          <div
                            className="spinner-border spinner-border-sm text-success"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        ) : (
                          <span
                            data-toggle="tooltip"
                            data-placement="left"
                            title="Incremental Sync"
                          >
                            <FaSyncAlt onClick={() => incrementRun(res.Id)} />
                          </span>
                        )}
                      </IconContext.Provider>
                    </div>
                  </td>
                  <td>
                    {res.LastIncrementalTriggerDate === null
                      ? ""
                      : moment(moment.utc(res.LastIncrementalTriggerDate))
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default XeroProductList;
