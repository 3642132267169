import { useEffect } from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
// Redux Info
import { useAppSelector , useAppDispatch } from '../redux/hooks';
import {selectApi } from '../redux/reducers/MainReducer';
import {setUpFailedCreateNewBudget2sqlSubAreaModal,
        createNewBudget2sqlSubAreaAsync,
        setUpCreateNewBudget2sqlSubAreaAdded,
        selectCreateNewBudget2sqlSubAreaAdded,
        selectCreateNewBudget2sqlSubAreaModal,
        budgetSubAreaAsync} from '../redux/reducers/Budget2SqlReducer';
import { InfoModal } from "./shared/InfoModal"; 

interface IFormInput {
    name: string;
}

interface IBudgetCreateSubAreaProps {
    areaId: string
}

const BudgetCreateSubArea = (props:IBudgetCreateSubAreaProps) => {
    const {  register,
        handleSubmit} = useForm<IFormInput>({ 
        mode: "onChange"
    });
    
    // Redux Selector
    const api = useAppSelector(selectApi);
    const modalState = useAppSelector(selectCreateNewBudget2sqlSubAreaModal);
    const isSubAreaAdded= useAppSelector(selectCreateNewBudget2sqlSubAreaAdded);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(isSubAreaAdded){
            const id = props.areaId
            dispatch(budgetSubAreaAsync({api, id}))
        } 
    }, [isSubAreaAdded]);
    

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        const subAreaName = data.name
        const listOrder:any = []
        const areaId = props.areaId
        dispatch(createNewBudget2sqlSubAreaAsync({api, subAreaName, listOrder, areaId}))
    };
    

    return (
        <div>
            <InfoModal
                header={modalState.header}
                message={modalState.message}
                open={modalState.open}
                success={modalState.success}
                onOK={() => {
                    dispatch(setUpFailedCreateNewBudget2sqlSubAreaModal())
                }}
            />
            <button 
                hidden={!isSubAreaAdded}
                className ="btn btn-sm btn-primary"
                onClick = {()=> dispatch(setUpCreateNewBudget2sqlSubAreaAdded(false))}>Add New Sub Area</button>
            <form onSubmit={handleSubmit(onSubmit)}> 
                <br />
                <div className="row">
                    <div className="form-group col-6">
                        <input type="text"
                            className="form-control" 
                            id="name" 
                            hidden={isSubAreaAdded}
                            placeholder="Enter the subarea name"
                            {...register("name", { required: true})} 
                        />
                    </div>
                    <div className="form-group col-2 mr-1">
                        <button 
                            type="submit" 
                            hidden={isSubAreaAdded}
                            className="btn btn-md btn-primary text-uppercase" 
                        >Add</button>
                    </div>
                    <div className="form-group col-2">
                        <button 
                            className="btn btn-secondary btn-md text-uppercase"
                            hidden={isSubAreaAdded}
                            onClick={()=>dispatch(setUpCreateNewBudget2sqlSubAreaAdded(true))} 
                        >Cancel</button>
                    </div>
                </div> 
            </form>
        </div>
    )
}

export default BudgetCreateSubArea;