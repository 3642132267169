import XeroApi from "./XeroApi";
import {User} from "../../../interfaces/User";
import {XeroProduct} from "../../../interfaces/Xero"

class V1XeroApi implements XeroApi  {
    fetchCustom: (endpoint: string, ...args: any) => Promise<any>;

    constructor(fetchCustom: any) {
        this.fetchCustom = fetchCustom;
    }
    

    getAllXero2sqlProducts = (): Promise<XeroProduct[]> => {
        return this.fetchCustom('/me/xero2sqlproducts');
    }
    
    getAllUsers = (): Promise<User[]> => {
        return this.fetchCustom('/users');
    }

    getAllProducts = (): Promise<XeroProduct[]> => {
        return this.fetchCustom('/xero2sqlproducts');
    }

    createNewXero2sqlProduct = (xero2sqlProduct:object): Promise<XeroProduct[]> => {
        return this.fetchCustom(`/xero2sqlproducts`, {
            method: 'POST',
            body: JSON.stringify(xero2sqlProduct)
        });
    }

    incrementalRunXero2sqlProduct = (Id: number): Promise<XeroProduct[]> => {
        return this.fetchCustom(`/xero2sqlproducts/${Id}/incremental`, {
            method: 'POST',
            //body: JSON.stringify(xero2sqlProduct)
        });
    }

    linkUserToXero2sqlProduct = (Id: string, xero2SqlProductId:string): Promise<XeroProduct[]> => {
        return this.fetchCustom(`/users/${Id}/link-xero2sqlproduct`, {
            method: 'POST',
            body: JSON.stringify({xero2SqlProductId})
        });
    }  

}

export default V1XeroApi;