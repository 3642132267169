import BudgetApi from "./BudgetApi";
import {BudgetProduct} from "../../../interfaces/Budget"

class V1BudgetApi implements BudgetApi  {
    fetchCustom: (endpoint: string, ...args: any) => Promise<any>;

    constructor(fetchCustom: any) {
        this.fetchCustom = fetchCustom;
    }
    

    getAllBudget2sqlProducts = (): Promise<BudgetProduct[]> => {
        return this.fetchCustom('/me/budget2sqlproducts');
    }

    getAllBudgetProducts = (): Promise<BudgetProduct[]> => {
        return this.fetchCustom('/budget2sqlproducts');
    }

    getAllBudget2sqlArea = (Budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/areas/${Budget2SqlProductId}`);
    }

    getAllBudget2sqlVersion = (Budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/versions/${Budget2SqlProductId}`);
    }

    getAllBudget2sqlHelperTable = (Budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/helperTables/${Budget2SqlProductId}`);
    }

    getAllAreasSubArea= (AreaId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/subareas/${AreaId}`);
    }

    getAllVersionsMilestone= (VersionId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/milestones/${VersionId}`);
    }


    createNewBudget2sqlProduct = (budget2sqlProduct:object): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/budget2sqlproducts`, {
            method: 'POST',
            body: JSON.stringify(budget2sqlProduct)
        });
    }

    createNewBudget2sqlArea = (name: string, budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/areas`, {
            method: 'POST',
            body: JSON.stringify({name, budget2SqlProductId})
        });
    }

    createNewBudget2sqlVersion = (name: string, budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/versions`, {
            method: 'POST',
            body: JSON.stringify({name, budget2SqlProductId})
        });
    }

    createNewBudget2sqlHelperTable = (name: string, inputTableName: string, budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/helperTables`, {
            method: 'POST',
            body: JSON.stringify({name, inputTableName, budget2SqlProductId})
        });
    }

    createNewBudget2sqlSubArea= (name: string, listOrder:string, areaId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/subareas`, {
            method: 'POST',
            body: JSON.stringify({name, listOrder, areaId})
        });
    }

    createNewBudget2sqlMilestone= (name: string, listOrder:string, versionId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/milestones`, {
            method: 'POST',
            body: JSON.stringify({name, listOrder, versionId})
        });
    }

    linkUserToBudget2sqlProduct = (Id: string, budget2SqlProductId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/users/${Id}/link-budget2sqlproduct`, {
            method: 'POST',
            body: JSON.stringify({budget2SqlProductId})
        });
    } 

    linkUserToHelperTable = (Id: string, helperTableId:string): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/users/${Id}/link-helperTable`, {
            method: 'POST',
            body: JSON.stringify({helperTableId})
        });
    } 
    
    linkUserToSubArea = (Id: string, subareaId:string, ListOrder:[]): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/users/${Id}/link-subarea`, {
            method: 'POST',
            body: JSON.stringify({subareaId, ListOrder})
        });
    }

    linkUserToMilestone = (Id: string, milestoneId:string, ListOrder:[]): Promise<BudgetProduct[]> => {
        return this.fetchCustom(`/users/${Id}/link-milestone`, {
            method: 'POST',
            body: JSON.stringify({milestoneId, ListOrder})
        });
    }

}

export default V1BudgetApi;