import React ,{ useEffect }from "react";
import { withRouter, RouteComponentProps } from 'react-router';
import { Route, Switch, Redirect, useHistory} from "react-router-dom";
import Login from './components/Login';
import Register from './components/Register';
import './App.css';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import XeroProductList from './components/XeroProductList';
import BudgetProductList from './components/BudgetProductList';
import XeroProductCreate from './components/XeroProductCreate';
import BudgetProductCreate from './components/BudgetProductCreate';
import AssignProduct from './components/AssignProduct';
import BudgetAssignProduct from './components/BudgetAssignProduct';
import {SESSION_STORAGE_VAR, SESSION_USERROLE_VAR} from './services/api/V1ApiService';
// Redux Info
import { useAppSelector, useAppDispatch} from './redux/hooks';
import {selectApi, userAsync, selectIsAuthorized, selectUserRole} from './redux/reducers/MainReducer';
import {budgetAllProductAsync} from './redux/reducers/Budget2SqlReducer';
import {xeroAllProductAsync} from './redux/reducers/Xero2SqlReducer';

const unauthenticatedPages = ['/login', '/register', 'forgot-password','/'];

const userRoleFromlocalStorage =
  localStorage.getItem(SESSION_USERROLE_VAR) || undefined;
  

const App: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const history = useHistory();
  // Redux Selector
  const api = useAppSelector(selectApi);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const userRole = useAppSelector(selectUserRole);

  const adminRight = userRole === "SuperAdmin" || userRoleFromlocalStorage === "SuperAdmin"

  const dispatch = useAppDispatch();

  useEffect(() => {
    const tokenFromlocalStorage = localStorage.getItem(SESSION_STORAGE_VAR);
    if (tokenFromlocalStorage) {
      api?.setAuthToken(tokenFromlocalStorage);
      if(unauthenticatedPages.find(u => u === props.location.pathname)) {
        history.push('/xero2sql-products')
      }
      
    } else {
      // only reroute if we're not currently on a page that's allowed for unauthenticated sessions
      if(!unauthenticatedPages.find(u => u === props.location.pathname)) {
        history.push(unauthenticatedPages[0])
      }
    }

    if(isAuthorized){
      history.push("/login")
    }
    dispatch(budgetAllProductAsync({api, adminRight}))
    dispatch(userAsync())
    dispatch(xeroAllProductAsync({api, adminRight}))
    
  }, []);  

  return (
    <div className="App">
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/login"  />} />
          <Route path="/login" exact component={
            () => <Login />}/>
            <Route path="/register" exact component={
            () => <Register />}/>
          <Route path="/forgot-password" exact component={
            () => <ForgotPassword />} />
          <Route path="/reset-password" exact component={
            () => <ResetPassword />} />
          <Route path="/xero2sql-products" exact component={
            () => <XeroProductList />} />
            <Route path="/budget2sql-products" exact component={
            () => <BudgetProductList />} />
          <Route path="/xero2sql-create-product" exact component={
            () => <XeroProductCreate />} />
          <Route path="/budget2sql-create-product" exact component={
            () => <BudgetProductCreate />} />
          <Route path="/assign-product" exact component={
            () => <AssignProduct />} />
          <Route path="/budget-assign-product" exact component={
            () => <BudgetAssignProduct />} />
        </Switch>
      </div> 
  );
}

export default withRouter(App);
