import {useState } from "react";
import BudgetCreateArea from './BudgetCreateArea';
import BudgetCreateVersion from './BudgetCreateVersion';
import BudgetCreateHelperTable from './BudgetCreateHelperTable';
import BudgetCreateSubArea from './BudgetCreateSubArea';
import BudgetCreateMilestone from './BudgetCreateMilestone';
import Modal from 'react-modal';
import { useAppSelector, useAppDispatch  } from '../redux/hooks';
import {selectApi } from '../redux/reducers/MainReducer';
import {budgetAreaAsync,
        selectBudgetArea,
        selectBudgetAreaLoading,
        budgetVersionAsync,
        budgetHelperTableAsync,
        selectBudgetVersion,
        selectBudgetVersionLoading,
        selectBudgetHelperTable,
        selectBudgetHelperTableLoading,
        budgetMilestoneAsync,
        selectBudgetMilestoneLoading,
        selectBudgetMilestone,
        budgetSubAreaAsync,
        selectBudgetSubAreaLoading,
        selectBudgetSubArea} from '../redux/reducers/Budget2SqlReducer';
import Spinner from "./Spinner";


interface IBudgetProductProps {
    companyName: string;
    productId: string
}

const BudgetProduct = (props:IBudgetProductProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMilestoneOpen, setIsMilestoneOpen] = useState(false);
    const [isSubAreaOpen, setIsSubAreaOpen] = useState(false);
    const [budgetAreaId, setBudgetAreaId] = useState<string>("");
    const [budgetVersionId, setBudgetVersionId] = useState<string>("");

    // Redux Selector
    const api = useAppSelector(selectApi);
    const budgetAreaList = useAppSelector(selectBudgetArea);
    const budgetAreaLoading = useAppSelector(selectBudgetAreaLoading);
    const budgetVersionList = useAppSelector(selectBudgetVersion);
    const budgetVersionLoading = useAppSelector(selectBudgetVersionLoading);
    const budgetHelperTableList = useAppSelector(selectBudgetHelperTable);
    const budgetHelperTableLoading = useAppSelector(selectBudgetHelperTableLoading);
    const budgetMileStoneList = useAppSelector(selectBudgetMilestone);
    const budgetMileStoneLoading= useAppSelector(selectBudgetMilestoneLoading);
    const budgetSubAreaList = useAppSelector(selectBudgetSubArea);
    const budgetSubAreaLoading= useAppSelector(selectBudgetSubAreaLoading);

    const dispatch = useAppDispatch();

    const openBudgetModal = ()=> {
        const id = props.productId
        setIsModalOpen(true)
        dispatch(budgetAreaAsync({api, id}))
        dispatch(budgetVersionAsync({api, id}))
        dispatch(budgetHelperTableAsync({api, id}))
    }
    
    Modal.setAppElement('body');
    
    const setVersionId = (id:string)=> {
        setBudgetVersionId(id)
        setIsMilestoneOpen(true)
        dispatch(budgetMilestoneAsync({api, id}))
    }
    const setSubAreaId = (id:string)=> {
        setBudgetAreaId(id)
        setIsSubAreaOpen(true)
        dispatch(budgetSubAreaAsync({api, id}))
    }

    return (
        <div>
            <div 
                className="col-12"
                onClick={()=> openBudgetModal()} >{props.companyName}</div>
            <Modal  isOpen={isModalOpen}
            contentLabel="Modal"
            style={{
               content: {
                   minWidth:'40px',
                   width: '50%',
                   height: '70%',
                   top: '50%',
                   left: '50%',
                   right: 'auto',
                   marginRight: '-50%',
                   transform: 'translate(-50%, -50%)',
                   textAlign: "center"
                 },
                 overlay: {
                   //zIndex: 10
                 }
             }}>
                <div>
                    <button 
                    onClick = {() => setIsModalOpen(false)}
                    type="submit" 
                    className="btn btn-primary ">Close</button>
                </div>
                <br/>
                <div className="text-center row "> 
                    <div className="col-12 ">
                         <div id="accordion">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                            <h5 className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <button className="btn btn-link" >
                                    Areas Table
                                </button>
                            </h5>
                            </div>

                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div className="card-body">
                                <BudgetCreateArea productId={props.productId}/>
                                {budgetAreaLoading? <Spinner /> : budgetAreaList?.map((res:any, key:number)=>
                                <div>
                                    <a   className="text-decoration-none"><p onClick={()=>setSubAreaId(res.Id)}>
                                        {key+1}. {res.Name} 
                                    </p></a>
                                </div> 
                                
                                 )}
                            </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                            <h5 className="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <button className="btn btn-link collapsed" >
                                    Versions Table
                                </button>
                            </h5>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                                <BudgetCreateVersion productId={props.productId} />
                                {budgetVersionLoading? <Spinner />: budgetVersionList?.map((res:any, key:number)=> 
                                 <div>
                                    <a  className="text-decoration-none"><p onClick={()=>setVersionId(res.Id)}>
                                        {key+1}. {res.Name} 
                                    </p></a>
                                 </div>
                                
                                 )}
                                
                            </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                            <h5 className="mb-0" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <button className="btn btn-link collapsed" >
                                    Helper Table
                                </button>
                            </h5>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div className="card-body">
                                <BudgetCreateHelperTable productId={props.productId} />
                                {budgetHelperTableLoading? <Spinner />: budgetHelperTableList?.map((res:any, key:number) => 
                                    <p>{key+1}.{res.Name}</p>
                                )}
                                
                                </div>
                                </div>
                            
                        </div>
                        </div>

                    </div>
                   
                </div>
                
            </Modal>
            <Modal  isOpen={isMilestoneOpen} 
             contentLabel="Modal"
             style={{
                content: {
                    width: '50%',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: "center"
                  },
                  overlay: {
                    //zIndex: '10'
                  }
              }}>
                <div>
                    <button 
                    onClick = {() => setIsMilestoneOpen(false)}
                    type="submit" 
                    className="btn btn-primary ">Close</button>
                </div>
                <br/>
                <div className="card card-body">
                    <BudgetCreateMilestone versionId = {budgetVersionId}/>
                    {budgetMileStoneLoading? <Spinner />: budgetMileStoneList?.map((res:any, key:number)=>
                        <p>{key+1}.{res.Name}</p>
                    )}
                </div>

            </Modal>
            <Modal  isOpen={isSubAreaOpen} 
             contentLabel="Modal"
             style={{
                content: {
                    width: '50%',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: "center"
                  },
                  overlay: {
                    //zIndex: '10'
                  }
              }}>
                <div>
                    <button 
                    onClick = {() => setIsSubAreaOpen(false)}
                    type="submit" 
                    className="btn btn-primary ">Close</button>
                </div>
                <br/>
                <div className="card card-body">
                    <BudgetCreateSubArea areaId = {budgetAreaId}/>
                    {budgetSubAreaLoading?<Spinner />:budgetSubAreaList?.map((res:any, key:number)=>
                        <p>{key+1}.{res.Name}</p>
                    )}
                </div>

            </Modal>
        </div>
        

    )
}

export default BudgetProduct;