import { useEffect, useState } from "react";
import { Link} from "react-router-dom";
// Redux Info
import { useAppSelector, useAppDispatch} from "../redux/hooks";
import {
  selectApi,
  selectUserRole,
} from "../redux/reducers/MainReducer";
import {budgetAllProductAsync,
        selectBudgetAllProduct,
        selectBudgetAllProductLoading, } from "../redux/reducers/Budget2SqlReducer"
import NavBar from "../components/NavBar";
import Spinner from "../components/Spinner";
import { SESSION_USERROLE_VAR } from "../services/api/V1ApiService";
import { FaUsersCog } from "react-icons/fa";
import { IconContext } from "react-icons";
import { InfoModal } from "./shared/InfoModal";
import BudgetProduct from "../components/BudgetProduct";

const userRoleFromlocalStorage =
  localStorage.getItem(SESSION_USERROLE_VAR) || undefined;

const BudgetProductList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  // Redux Selector
  const api = useAppSelector(selectApi);
  const budgetProductList = useAppSelector(selectBudgetAllProduct);
  const loading = useAppSelector(selectBudgetAllProductLoading);
  const userRole = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();

  const adminRight = userRole === "SuperAdmin" || userRoleFromlocalStorage === "SuperAdmin"
  useEffect(() => {
    dispatch(budgetAllProductAsync({api, adminRight}))
  }, []);

  // 3 states here can be useful - before loaded budgetProductList is undefined, then after loaded it is either [] or has products
  // means we can know - still loading, loaded but no products, loaded with >0 products
  if (budgetProductList && !budgetProductList.length) {
    return (
      <div>
        <NavBar /> 
        <br />
        <br /> 
      { loading?<Spinner />:(
      <div>
        <br />
        <h2>No products registered</h2>
        <p>Please contact Larasoft in order to link your Budget2SQL Entities to your portal account.</p>
      </div>
      )}
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <InfoModal
        header="Sync Started"
        message="Your sync has started successfully. Typically this will take 10-15 minutes to finish."
        open={modalOpen}
        success={true}
        onOK={() => setModalOpen(false)}
      />
      <InfoModal
        header="Sync Failed"
        message="Your sync has failed. Please ensure you wait 15 minutes from the previous start time"
        open={errorModalOpen}
        success={false}
        onOK={() => setErrorModalOpen(false)}
      />
      <div className="row">
        <h2 className="col-8 text-center">My Entities</h2>
        {adminRight ? (
          <div className="col-4">
            <Link to="budget-assign-product" className="small text-body">
              <IconContext.Provider
                value={{ size: "40px", className: "text-primary text-right" }}
              >
                <span
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Link user to a product"
                >
                  <FaUsersCog />
                </span>
              </IconContext.Provider>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Entity Name</th>
            </tr>
          </thead>
          <br />
          <tbody>
            {loading ? (
              <Spinner />
            ) : (
              budgetProductList?.map((res: any) => (
                <tr>
                    <td className="row">
                    
                      <BudgetProduct 
                        companyName = {res.CompanyName}
                        productId = {res.Id}
                      />
                    
                    </td>
                  
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BudgetProductList;
