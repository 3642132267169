import { useEffect } from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
// Redux Info
import { useAppSelector , useAppDispatch } from '../redux/hooks';
import {setUpFailedCreateNewBudget2sqlMilestoneModal,
        createNewBudget2sqlMilestoneAsync,
        setUpCreateNewBudget2sqlMilestoneAdded,
        selectCreateNewBudget2sqlMilestoneAdded,
        selectCreateNewBudget2sqlMilestoneModal,
        budgetMilestoneAsync} from '../redux/reducers/Budget2SqlReducer';
import {selectApi } from '../redux/reducers/MainReducer';
import { InfoModal } from "./shared/InfoModal";

interface IFormInput {
    name: string;
}

interface IBudgetCreateMilestoneProps {
    versionId: string
}

const BudgetCreateMilestone = (props:IBudgetCreateMilestoneProps) => {
    const {  register,
        handleSubmit} = useForm<IFormInput>({ 
        mode: "onChange"
    });

    
    // Redux Selector
    const api = useAppSelector(selectApi);
    const modalState = useAppSelector(selectCreateNewBudget2sqlMilestoneModal);
    const isMilestoneAdded= useAppSelector(selectCreateNewBudget2sqlMilestoneAdded);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(isMilestoneAdded){
            const id = props.versionId
            dispatch(budgetMilestoneAsync({api, id}))
        } 
    }, [isMilestoneAdded]);
    

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        const milestoneName = data.name
        const listOrder:any = []
        const versionId = props.versionId
        dispatch(createNewBudget2sqlMilestoneAsync({api, milestoneName, listOrder, versionId}))        
    };
    

    return (
        <div>
            <InfoModal
                header={modalState.header}
                message={modalState.message}
                open={modalState.open}
                success={modalState.success}
                onOK={() => {
                    dispatch(setUpFailedCreateNewBudget2sqlMilestoneModal())
                }}
            />
            <button 
                hidden={!isMilestoneAdded}
                className ="btn btn-sm btn-primary"
                onClick = {()=> dispatch(setUpCreateNewBudget2sqlMilestoneAdded(false))}>Add New Milestone</button>
            <form onSubmit={handleSubmit(onSubmit)}> 
                <br />
                <div className="row">
                    <div className="form-group col-6">
                        <input type="text"
                            className="form-control" 
                            id="name" 
                            hidden={isMilestoneAdded}
                            placeholder="Enter the milestone name"
                            {...register("name", { required: true})} 
                        />
                    </div>
                    <div className="form-group col-2 mr-1">
                        <button 
                            type="submit" 
                            hidden={isMilestoneAdded}
                            className="btn btn-md btn-primary text-uppercase"
                        >Add</button>
                    </div>
                    <div className="form-group col-2">
                        <button 
                            className="btn btn-secondary btn-md text-uppercase"
                            hidden={isMilestoneAdded}
                            onClick={()=>dispatch(setUpCreateNewBudget2sqlMilestoneAdded(true))} 
                        >Cancel</button>
                    </div>
                </div> 
            </form>
        </div>
    )
}

export default BudgetCreateMilestone;