import { useState } from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
import {useHistory } from "react-router-dom";
// Redux Info
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import {
    selectApi
  } from "../redux/reducers/MainReducer";
import {createNewXero2sqlProductAsync, selectCreateNewXero2sqlProductError} from '../redux/reducers/Xero2SqlReducer';
import NavBar from './NavBar'

interface IFormInput {
    companyName: string;
    xeroTenantId: string;
    awsSecretName:string
}

const XeroProductCreate = () => {
    const {  register,
        handleSubmit,
        formState: { isValid, errors }} = useForm<IFormInput>({ 
        mode: "onChange"
    });


    const history = useHistory();
    
    const api = useAppSelector(selectApi);
    const isError = useAppSelector(selectCreateNewXero2sqlProductError);
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        dispatch(createNewXero2sqlProductAsync({api, data}))
    };
    

    return (
        <div>
            <NavBar />
            <div className="row justify-content-center">
                <div className="col-lg-8 ">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">Create New Xero2SQL products</h3>
                            
                            <form onSubmit={handleSubmit(onSubmit)}> 
                                <br /> 
                                <div className="form-group">
                                    <label className="text-right" htmlFor="companyName">Entity Name</label>
                                    <input type="text"
                                        className="form-control" 
                                        id="companyName" 
                                        placeholder="Enter Your Company Name"
                                        {...register("companyName", { required: true})} 
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.companyName && <p>Please enter your Company Name</p>}</small>
                                </div>
                                <div className="form-group">
                                    <label className="text-right" htmlFor="xeroTenantId">Xero Tenant Id</label>
                                    <input type="test"
                                        className="form-control" 
                                        id="xeroTenantId"
                                        placeholder="Enter Your Xero Tenant Id"
                                        {...register("xeroTenantId", { required: true})}
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.xeroTenantId && <p>Please enter your Xero Tenant Id</p>}</small>
                                </div>
                                <div className="form-group">
                                    <label className="text-right" htmlFor="awsSecretName">AWS Secret Name</label>
                                    <input type="text"
                                        className="form-control" 
                                        id="awsSecretName" 
                                        placeholder="Enter Your Aws Secret Name"
                                        {...register("awsSecretName", { required: true})} 
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.awsSecretName && <p>Please enter your Aws Secret Name</p>}</small>
                                </div>
                                <div>
                                    <button 
                                        type="submit" 
                                        className="btn btn-lg btn-primary btn-login text-uppercase mb-2 mx-2"
                                        data-toggle="modal" data-target={isValid?"#createNewProductMessage":""} 
                                    >Add</button>
                                    <button 
                                        className="mx-2 btn btn-secondary btn-lg btn-login text-uppercase mb-2"
                                        onClick={()=>history.push("/xero2sql-products")} 
                                    >Cancel</button>
                                </div>
                                {/* Modal*/}
                                {<div className="modal fade" id="createNewProductMessage" tabIndex={-1} role="dialog" aria-labelledby="createNewProductMessageLabel" aria-hidden="true">
                                        <div className="modal-dialog " role="document">
                                            <div className="modal-content">
                                            <div className={isError?"modal-header bg-danger":"modal-header bg-success"}>
                                                <h5 className="modal-title text-center text-bold " id="createNewProductMessageLabel">{isError?"ERROR":"SUCCESS"}</h5>
                                            </div>
                                                <div className="modal-body">
                                                    <p>{isError?'Sorry, error occured. This product already exist':'Your new product has been added'}</p>
 
                                                    <button 
                                                        type="button"
                                                        data-dismiss="modal" 
                                                        aria-label="Close"  
                                                        className="close btn btn-lg btn-primary btn-login text-uppercase mb-2"
                                                        onClick = {()=> {history.push("/xero2sql-create-product")}}
                                                    >OK</button>
                                                </div>
                                            </div>
                                    </div>
                                </div>}
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default XeroProductCreate;