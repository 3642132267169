import { useState } from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
import {useHistory } from "react-router-dom";
// Redux Info
import { useAppSelector } from '../redux/hooks';
import {selectApi } from '../redux/reducers/MainReducer';
import NavBar from './NavBar'
import { InfoModal } from "./shared/InfoModal";

interface IModalState {
    header: string;
    message: string;
    success: boolean;
    open: boolean;
  }

interface IFormInput {
    companyName: string;
}

const BudgetProductCreate = () => {
    const [modalState, setModalState] = useState<IModalState>({
        header: "",
        message: "",
        success: true,
        open: false,
      });
    const [isError, setIsError] = useState(false)
    const {  register,
        handleSubmit,
        formState: { isValid, errors }} = useForm<IFormInput>({ 
        mode: "onChange"
    });


    const history = useHistory();
    
    // Redux Selector
    const api = useAppSelector(selectApi);
    

    
    

    const onSubmit: SubmitHandler<IFormInput> = (data:any) => {
        api?.budget?.createNewBudget2sqlProduct(data).then((res:any) => {
            res.error|| res.statusCode===500?setIsError(true):setIsError(false)
            setModalState({
                header: "Success",
                message:
                    "Adding new budget product succeed",
                success: true,
                open: true,
              });
              if(res.error || res.statusCode){                
                setModalState({
                    header: " Adding new budget product failed",
                    message:
                        `Please try again. This budget product exist already`,
                    success: false,
                    open: true,
                })}
        }).catch((err:any) => {
            setModalState({
                header: " Adding new budget product failed",
                message:
                    `Please try again. This budget product exist already`,
                success: false,
                open: true,
            })
        })
        
    };
    

    return (
        <div>
            <NavBar />
            <InfoModal
                header={modalState.header}
                message={modalState.message}
                open={modalState.open}
                success={modalState.success}
                onOK={() => {
                    setModalState({ ...modalState, open: false });
                    //modalState.success && history.push("/budget2sql-products");
                    //window.location.href = '/budget2sql-products'
                }}
            />
            <div className="row justify-content-center">
                <div className="col-lg-8 ">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">Create New Budget2SQL products</h3>
                            
                            <form onSubmit={handleSubmit(onSubmit)}> 
                                <br /> 
                                <div className="form-group">
                                    <label className="text-right" htmlFor="companyName">Entity Name</label>
                                    <input type="text"
                                        className="form-control" 
                                        id="companyName" 
                                        placeholder="Enter Your Company Name"
                                        {...register("companyName", { required: true})} 
                                    />
                                </div>
                                <div className="form-check">
                                    <small className="form-text text-danger">{errors.companyName && <p>Please enter your Company Name</p>}</small>
                                </div>
                                
                                <div>
                                    <button 
                                        type="submit" 
                                        className="btn btn-lg btn-primary btn-login text-uppercase mb-2 mx-2"
                                        data-toggle="modal" data-target={isValid?"#createNewProductMessage":""} 
                                    >Add</button>
                                    <button 
                                        className="mx-2 btn btn-secondary btn-lg btn-login text-uppercase mb-2"
                                        onClick={()=>history.push("/budget2sql-products")} 
                                    >Cancel</button>
                                </div>
                                {/* Modal*/}
                                {<div className="modal fade" id="createNewProductMessage" tabIndex={-1} role="dialog" aria-labelledby="createNewProductMessageLabel" aria-hidden="true">
                                        <div className="modal-dialog " role="document">
                                            <div className="modal-content">
                                            <div className={isError?"modal-header bg-danger":"modal-header bg-success"}>
                                                <h5 className="modal-title text-center text-bold " id="createNewProductMessageLabel">{isError?"ERROR":"SUCCESS"}</h5>
                                            </div>
                                                <div className="modal-body">
                                                    <p>{isError?'Sorry, error occured. This product already exist':'Your new product has been added'}</p>
 
                                                    <button 
                                                        type="button"
                                                        data-dismiss="modal" 
                                                        aria-label="Close"  
                                                        className="close btn btn-lg btn-primary btn-login text-uppercase mb-2"
                                                        onClick = {()=> {history.push("/budget2sql-create-product")}}
                                                    >OK</button>
                                                </div>
                                            </div>
                                    </div>
                                </div>}
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BudgetProductCreate;